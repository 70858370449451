Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiType = 'PUT';
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.profile = "Profile";
exports.btnExampleTitle = "CLICK ME";
exports.getProfileAPiEndPoint = "account_block/find_account"
exports.geteElearningAPiEndPoint = "bx_block_gamification/elearnings"
// Customizable Area End