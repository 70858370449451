export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logoutIcon = require("../assets/logoutIcon.png");
export const soundIcon = require("../assets/soundIcon.png");
export const privacyIcon = require("../assets/privacyIcon.png");
export const notificationIcon = require("../assets/notificationIcon.png");
export const versionIcon = require("../assets/versionIcon.png");
export const settingIcon = require("../assets/settingIcon.png");
export const termsIcon = require("../assets/termsIcon.png");
export const backIcon = require("../assets/termsIcon.png");
