import React from "react";

// Customizable Area Start
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  TextInput,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity,
  Dimensions,
} from "react-native";

import { Button } from "react-native-elements";
import Modal from "react-native-modal";
import {resetPasswordLogo, rightTick} from './assets'
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {IsVisible} = this.state
    // Customizable Area End
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            Platform.OS === "web" ? styles.containerWeb : styles.containerMobile
          }
        >
          {/* ------------------- HEADER ---------------------- */}
          {/* Customizable Area Start */}
          <View>
            <View style={styles.headline}>
            <Image
              source={resetPasswordLogo}
              style={styles.logoStyle}
              />
{/*  */}
              {/* <Text style={styles.titleText}>Logo</Text> */}

              <Text style={styles.titleText}>
                Reset Password
              </Text>

              <Text style={styles.titleline2}>Now You Can Enter Your New Password.</Text>

            </View>
          </View>
          {/* -------------------- BODY ----------------------- */}

          <View>
            <View>
              <Text style={[styles.lablestyle, { marginTop: 40 }]}>Enter New Password</Text>
              <View style={{ justifyContent: 'center' }}>
                <TextInput
                  testID={"txtInputPassword"}
                  style={styles.bgPasswordInput}
                  placeholder={this.placeholderIsPassword}
                  placeholderTextColor={'#9C9C9C'}
                  {...this.txtNewPasswordProps}
                  secureTextEntry={this.state.enablePasswordField ? true : false}
                />
                <TouchableOpacity
                testID="btn1"
                  style={styles.passwordShowHide}
                  onPress={() => { 
                    this.setState({
                      enablePasswordField: !this.state.enablePasswordField
                    });
                  }}
                >
                  <Image
                    style={styles.imgPasswordShowhide}
                    source={
                      this.state.enablePasswordField
                        ? this.imgPasswordVisible
                        : this.imgPasswordInVisible
                    }
                  />
                </TouchableOpacity>
              </View>

              <View>
                {!this.state.NewpasswordValied ? (
                  <Text style={[styles.lablestyle,styles.errorStyle]}>Invalid Password</Text>
                ) : null}
              </View>

              <Text style={[styles.lablestyle]}>Re-enter New Password</Text>
              <View style={{ justifyContent: 'center' }} >
                <TextInput
                  testID={"txtInputConfirmPassword"}
                  style={styles.bgPasswordInput}
                  placeholder={this.placeholderIsReTypePassword}
                  placeholderTextColor={'#9C9C9C'}
                  {...this.txtNewPassword2Props}
                  secureTextEntry={
                    this.state.enablePasswordField2 ? true : false
                  }
                />

                <TouchableOpacity
                testID="btn2"
                  style={styles.passwordShowHide}
                  onPress={() => {
                    this.setState({
                      enablePasswordField2: !this.state
                        .enablePasswordField2
                    });
                  }}
                >
                  <Image
                    style={styles.imgPasswordShowhide}
                    source={
                      this.state.enablePasswordField2
                        ? this.imgPasswordVisible
                        : this.imgPasswordInVisible
                    }
                  />
                </TouchableOpacity>
              </View>
              <View>
                {this.state.NewPassword2 != ''? this.state.NewPassword2 != this.state.NewPassword ? (
                  <Text style={[styles.lablestyle,styles.errorStyle]}>
                    Passwords do NOT match!
                  </Text>
                ) : null:null}
              </View>

                <Button
                testID="btn3"
              buttonStyle={[styles.signUpbtn, { marginTop: Dimensions.get("window").height / 19 }]}
              containerStyle={{ paddingVertical: 10 }}
              onPress={() => this.ResetPasswordSave()}
              title="Save"
              titleStyle={{ color: 'rgb(35,1,0)', fontSize: 22, fontWeight: '400' }}
            />
            </View>
          </View>
          {/* @ts-ignore */}
          <Modal isVisible={IsVisible}>
            <View style={{backgroundColor:'#rgb(255,255,255)',height:330,width:300,alignSelf:'center',borderRadius: 7,padding: 10}}>
            <Image 
            style={{
              width: 100,
              height: 100,
              alignSelf:'center'
            }}
            source={rightTick}/>
            <Text style={styles.titleWhySignUp}>Congrats!</Text>
            <Text style={styles.titleline2}>Your Password has been</Text>
            <Text style={[styles.titleline2,{marginTop: 3}]}>reset Successfully.</Text>
            <Button
            testID="btn4"
              buttonStyle={[styles.signUpbtn, { marginTop: Dimensions.get("window").height / 29 ,height: Dimensions.get("window").height / 18,width:'60%',alignSelf:'center'}]}
              containerStyle={{ paddingVertical: 10 }}
              onPress={() => {this.ModalDone_btn()}}
              title="Done"
              titleStyle={{ color: 'rgb(35,1,0)', fontSize: 22, fontWeight: '400' }}
            />
            </View>
          </Modal>
          {/* Customizable Area End */}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  titleline2: {
    color: "#9C9C9C",
    fontSize: 13.2,
    fontWeight: 'bold',
    textAlign: "center",
    marginTop: 10,
  },
  logoStyle:{
    width:85,
    height:105,
    alignSelf:'center',
    marginTop: Dimensions.get("window").height / 11,
      },
  lablestyle: {
    marginTop: 15,
    color: "#rgb(1,1,1)",
    fontWeight: "700",
    fontSize: 14,
  },
  signUpbtn: {
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: '#FFCC01',
    //width: width / 1.085,
    height: Dimensions.get("window").height / 15
  },
  titleWhySignUp: {
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: "bold",
    color: "#rgb(1,1,1)",
    textAlign: 'center',
    marginTop: Dimensions.get("window").height / 51
  },
  ////
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650
  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center"
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%"
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1
  },

  mobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true
  },

  codeInput: {
    marginTop: 20,
    width: "30%"
  },

  phoneInput: {
    flex: 3,
    marginTop: 20
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    //marginTop:'15%',
    //fontSize: 20,
    //color: "#6200EE",
    //fontWeight: "bold",
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: "bold",
    color: "#rgb(1,1,1)",
    textAlign: 'center',
    marginTop: Dimensions.get("window").height / 55
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
      marginTop: 5,
      color:'#FF374F',
      fontSize: 14.5,
      fontWeight: '400',
    
  },


  passwordShowHide: {
    position: 'absolute',
    right: 9,
    top: '30%'
  },

  passwordRulesStyle: {
    padding: 15
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5
  },

  passwordContainerWeb: {
    flexDirection: "row",
    borderBottomWidth: 1,
    backgroundColor: "#00000000",
    borderColor: "#767676",
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  bgPasswordInput: {
    // color: "#9C9C9C",
    color: "#rgb(1,1,1)",
    flexDirection: "row",
    fontSize: 14.5,
    fontWeight: '400',
    textAlign: "left",
    backgroundColor: "#F7F7F7",
    marginTop: 5,
    borderWidth: 0.8,
    borderColor: "#E6E6E6",
    borderRadius: 7,
    includeFontPadding: true,
    padding: 13
  },

  passwordInputWeb: {
    flex: 1,
    fontSize: 18,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    borderColor: "#767676",
    borderRadius: 2
  },
  imgPasswordShowhide:{ height: 30, width: 30 }
});
// Customizable Area End
