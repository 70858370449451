// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Download from "../../blocks/Download/src/Download";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Gamification from "../../blocks/Gamification/src/Gamification";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import Analytics from "../../blocks/analytics/src/Analytics";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import MatchAlgorithm2 from "../../blocks/MatchAlgorithm2/src/MatchAlgorithm2";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import TaskList from "../../blocks/TaskList/src/TaskList";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Tasks from "../../blocks/tasks/src/Tasks";
import Task from "../../blocks/tasks/src/Task";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import MatchAlgorithm from "../../blocks/MatchAlgorithm/src/MatchAlgorithm";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import Settings from "../../blocks/Settings/src/Settings";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";






const routeMap = {
Settings:{
 component:Settings,
path:"/Settings"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},

Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
TermsAndConditions3:{
 component:TermsAndConditions3,
path:"/TermsAndConditions3"},
MatchAlgorithm:{
 component:MatchAlgorithm,
path:"/MatchAlgorithm"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},

VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},

CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Download:{
 component:Download,
path:"/Download"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Customform:{
 component:Customform,
path:"/Customform"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
MatchAlgorithm2:{
 component:MatchAlgorithm2,
path:"/MatchAlgorithm2"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;