import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  TextInput,
  FlatList,
  ScrollView,
  SafeAreaView,
} from "react-native";
import {
  notificationIcon,
  manuIcon,
  gridicon
} from './assets'
import { deviceWidth, scaledSize } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");
import { AnimatedCircularProgress } from 'react-native-circular-progress';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderGridView(item:{name:string, icon:any, screenName:string}){
    return(
      <TouchableOpacity style={styles.gridCardContainer}
        testID="gridCardPress"
        onPress={()=>this.navigateToScreen(item.screenName,{ gridListView: this.state.gridListView })}
      >
        <Image 
            source={item.icon}
            style={styles.gridCardIcon}
          />
        <Text style={{...styles.cardTitle, textAlign:'center'}}>{item.name}</Text>
      </TouchableOpacity>
    );
  }
  renderRowView(item:{name:string, icon:any, screenName:string}){
    return(
      <TouchableOpacity style={styles.rowCardContainer}
        testID="rowCardPress"
        onPress={()=>this.navigateToScreen(item.screenName,{ gridListView: this.state.gridListView })}
      >
        <Image 
          source={item.icon}
          style={styles.rowCardIcon}
        />
        <Text style={{...styles.cardTitle, flex:1}}>{item.name}</Text>
      </TouchableOpacity>
    );
  }
  // Customizable Area End
    
  render() {
    // Customizable Area Start
    let wish:string = '';
    if(5 <= this.time && this.time < 12){
      wish = configJSON.goodWishes[0];
    }else if(12 <= this.time && this.time < 17){
      wish = configJSON.goodWishes[1]; 
    }else{
      wish = configJSON.goodWishes[2];
    }
   
    return (
      <SafeAreaView style={styles.fullFlex}>
        <View style={styles.container}>
          <View >
            <View style={styles.cntHeader}>
              <TouchableOpacity 
                testID="profileSection"
                style={styles.profileSection}
                onPress={()=> this.onProfilePress()}
              >
                <Image
                    style={styles.PImage}
                    source={{ uri: this.state.profileData.profileImage }}
                  />
                <View>
                  <Text 
                    testID="fullName"
                    style={styles.NameStyle}
                  >
                    {this.state.profileData.fullName}
                  </Text>
                  <Text style={styles.designationText}>{this.state.profileData.designation}</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                testID="notification"
                onPress={this.gotoNotifications}
              >
                <Image 
                  source={notificationIcon}
                  style={styles.iconStyle}
                />
              </TouchableOpacity>
            </View>

            <View style={styles.searchBarView}>
              <TextInput
                style={styles.bgMobileInput}
                placeholder="Search Services"
              />
              <TouchableOpacity
                testID="displayViewChangeBTN"
                onPress={()=> this.changeView()}
              >
                <Image 
                  source={this.state.gridListView? manuIcon:gridicon}
                  style={styles.iconStyle}
                />
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Text testID="wishText" style={styles.firstNameLabel}>{`Hi ${this.state.profileData.firstName}, ${wish}!`}</Text>
            <View style={styles.TopGoalView}>
              <Text style={styles.goalProgressText}>{configJSON.goalProgress}</Text>
              <View style={{flexDirection:'row',alignItems:'center',paddingVertical:5}}>
                <AnimatedCircularProgress
                  size={scaledSize(40)}
                  width={scaledSize(4)}
                  fill={this.state.profileData.progressPer}
                  tintColor="#f7b500"
                  backgroundColor="#d4d4d4">
                  {
                    () => (
                      <Text style={styles.progressText}>
                        { `${this.state.profileData.progressPer}%` }
                      </Text>
                    )
                  }
                </AnimatedCircularProgress>
                <Text style={styles.achievedText}>{configJSON.achievedForThisMonth}</Text>
            </View>
            </View>
            <View>
              {
                this.state.gridListView?
                <FlatList
                  testID="gridList"
                  data={this.dashboardOptions}
                  columnWrapperStyle={{justifyContent: 'space-between'}}
                  renderItem={({item})=> this.renderGridView(item)}
                  numColumns={2}
                  key={2}
                  />
                :
                <FlatList
                  testID="rowList"
                  data={this.dashboardOptions}
                  renderItem={({item})=> this.renderRowView(item)}
                  key={1}
                />
              }
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
    fullFlex:{
      flex:1,
      backgroundColor:'#ffffff'
    },
    container: {
      flex: 1,
      paddingHorizontal: scaledSize(20),
      paddingTop: scaledSize(5),
      paddingBottom:0,
      backgroundColor: "#ffffff"
    },
    TopGoalView:{
      backgroundColor: "#F5F5F5",
      padding:scaledSize(10),
      borderRadius: scaledSize(10),
      marginVertical: scaledSize(10)
    },
    cntHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    profileSection:{
      flexDirection: 'row', 
      alignItems: 'center' 
    },
    PImage: {
      height: scaledSize(48),
      width: scaledSize(48),
      borderRadius: scaledSize(24),
      borderWidth: scaledSize(1),
      borderColor: '#FFCC00',
      marginRight: scaledSize(5)
    },
    NameStyle: {
      fontSize: scaledSize(20),
      fontWeight: "bold",
      color: "#000000",
    },
    iconStyle:{
      height: scaledSize(40),
      width:scaledSize(40)
    },
    searchBarView:{
      flexDirection: 'row', 
      alignItems: 'center', 
      marginTop: scaledSize(10),
      marginBottom: scaledSize(10), 
      justifyContent: 'space-between' 
    },
    firstNameLabel:{
      fontSize: scaledSize(13),
      fontWeight: "700",
      color: "#000000",
      alignSelf: 'flex-start',
    },
    goalProgressText:{
      fontSize: scaledSize(15),
      fontWeight: 'bold',
      color: "#000000",
      alignSelf: 'flex-start',
    },
    achievedText:{
      fontSize: scaledSize(13),
      fontWeight: '400',
      color: "#000000",
      marginLeft: scaledSize(10)
    },
    progressText:{
      fontSize: scaledSize(10),
      fontWeight: 'bold',
      color: "#000000",
    },
    cardTitle:{
      fontSize: scaledSize(14),
      fontWeight: 'bold',
      color: "#000000",
    },
    designationText: {
      color: "#84818A",
      fontSize: scaledSize(12),
      fontWeight: '400',
    },
    gridCardContainer: {
      backgroundColor: "#F5F5F5",
      height: (deviceWidth- scaledSize(60))/2,
      width: (deviceWidth- scaledSize(60))/2,
      borderWidth: scaledSize(1),
      borderColor: "#C1C1C1",
      borderRadius: scaledSize(20),
      justifyContent: 'space-around',
      padding:scaledSize(10),
      marginVertical: scaledSize(10)
    },
    rowCardContainer: {
      flex:1,
      backgroundColor: "#F7F7F7",
      marginVertical: scaledSize(10),
      borderWidth: scaledSize(1),
      borderRadius: scaledSize(10),
      borderColor: "#C1C1C1",
      height: (deviceWidth- scaledSize(60))/4,
      alignItems: 'center',
      flexDirection: 'row',
      paddingRight: scaledSize(10)
    },
    gridCardIcon:{
      width: scaledSize(55),
      height:scaledSize(55),
      alignSelf:'center'
    },
    rowCardIcon:{
      width: scaledSize(40),
      height:scaledSize(40),
      marginHorizontal: scaledSize(15),
      alignSelf:'center'
    },
    bgMobileInput: {
      color: "#000000",
      flexDirection: "row",
      fontSize: scaledSize(14),
      fontWeight: '400',
      textAlign: "left",
      backgroundColor: "#F2F2F2",
      width: '83%',
      borderRadius: scaledSize(7),
      includeFontPadding: true,
      padding: scaledSize(8)
    },
});
// Customizable Area End
