export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const likeIcon = require("../assets/likeIcon.png");
export const scoreIcon = require("../assets/scoreIcon.png");
export const unlikeIcon = require("../assets/unlikeIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const aboutAddIcon = require("../assets/aboutAddIcon.png");
export const aboutEmailIcon = require("../assets/aboutEmailIcon.png");
export const aboutPhoneIcon = require("../assets/aboutPhoneIcon.png");
export const aboutProductIcon = require("../assets/aboutProductIcon.png");
export const aboutDelIcon = require("../assets/aboutDelIcon.png");
export const aboutTimeIcon = require("../assets/aboutTimeIcon.png");
export const aboutLocationIcon = require("../assets/aboutLocationIcon.png");
export const aboutCountryIcon = require("../assets/aboutCountryIcon.png");
export const aboutGeneralIcon = require("../assets/aboutGeneralIcon.png");
export const user = require("../assets/user.png");
export const like = require("../assets/like.png");
export const trophy = require("../assets/trophy.png");
export const medal = require("../assets/medal.png");
export const image1 = require("../assets/1.png");
export const image2 = require("../assets/2.png");
export const Cross = require("../assets/Cross.png");
export const CalenderIcon = require("../assets/CalenderIcon.png");