import React from "react";


// Customizable Area Start
// Customizable Area End

import MultilevelApprovalController, {
  Props,
} from "./MultilevelApprovalController";

export default class MultilevelApproval extends MultilevelApprovalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     <div></div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
