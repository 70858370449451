import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Dimensions,
  Modal
} from "react-native";

const { width, height } = Dimensions.get('window');
import { Button } from "react-native-elements";
import CountryPicker from 'react-native-country-picker-modal'
import { Country } from './types'
// import Modal from "react-native-modal";
//@ts-ignore
import DatePicker from 'react-native-datepicker'
import { CalenderIcon, Cross } from './assets'
//@ts-ignore
import { Dropdown } from 'react-native-material-dropdown';
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";



export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      countryCode,
      withFilter,
      withFlag,
      withCountryNameButton,
      withAlphaFilter,
      withCallingCode,
      withEmoji,
      isVisible,
      dateOfJoining,
      dateOfbirth
    } = this.state
   

    let Dropdowndata = [{
      value: 'A+ (A Positive)',
      },
      {
      value: 'A- (A Negative)',
      },
      {
      value: 'B+ (B Positive)',
      },
      {
      value: 'B- (B Negative)',
      },
      {
      value: 'O+ (O Positive)',
      },
      {
      value: 'O- (O Negative)',
      },
      {
      value: 'AB+ (AB Positive)',
      },
      {
      value: 'AB- (AB Negative)',
    },
    ];
    const bordercolor=(valid:boolean) => {return valid ? "#BB5753" : "#E6E6E6"}

    // Customizable Area End
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID={"Background"}
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            <View>
              <View style={styles.headline}>
                <Text style={styles.signUpText}>Sign Up</Text>
              </View>

              <Text style={[styles.lablestyle, { marginTop: 49 }]}>Name</Text>
              <TextInput
                testID={"txtInputFirstName"}
                style={[styles.bgInput, { borderColor: bordercolor(this.state.firstNameVal)}]}
                placeholder={this.labelFirstName}
                placeholderTextColor={'#9C9C9C'}
                maxLength={30}
                {...this.txtInputFirstNamePrpos} //Merge Engine::From BDS - {...this.testIDProps}
              />
              {(this.state.textInputnumber == 1 && this.state.warning) && <Text style={{color:"red"}}>Blank spaces are not allowed</Text>}
              <Text style={styles.lablestyle}>Office Email ID</Text>
              <TextInput
                testID={"txtInputEmail"}
                autoCapitalize="none"
                style={[styles.bgInput, { borderColor: bordercolor(this.state.emailVal)}]}
                placeholder={this.labelEmail}
                placeholderTextColor={'#9C9C9C'}
                maxLength={30}
                {...this.txtInputEmailPrpos} //Merge Engine::From BDS - {...this.testIDProps}
              />
              {this.state.textInputnumber == 2 &&
              (this.state.warning ? <Text style={{color:"red"}}>Blank spaces are not allowed</Text>:
              (this.state.validwarning && <Text style={{color:"red"}}>Enter valid email</Text>))}
              <Text style={styles.lablestyle}>Mobile Number</Text>
              <View style={{
                flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  marginTop: 9,
                  borderWidth: 0.8,
                  borderColor: bordercolor(this.state.phoneVal),
                  borderRadius: 7,
                  backgroundColor: "#F7F7F7",
                overflow: 'hidden'
              }}>
                <View style={{ marginHorizontal: 6, left: 5 }}>
                  <CountryPicker
                    {...{
                      countryCode,
                      withFilter,
                      withFlag,
                      withCountryNameButton,
                      withAlphaFilter,
                      withCallingCode,
                      withEmoji,
                      onSelect:this.onSelect,
                    }}
                    visible={false}

                    //onClose={()=>{}}
                  />
                </View>
                <View style={{ borderLeftWidth: 1.4, height: '70%', borderColor: '#9C9C9C' }}></View>
                <TextInput
                  testID={"txtPhoneNumber"}
                  style={[{
                      color: "#rgb(1,1,1)",
                      flexDirection: "row",
                      fontSize: 14.5,
                    fontWeight: '400',
                      textAlign: "left",
                      backgroundColor: "#F7F7F7",
                      //marginTop: 9,
                      //borderWidth: 0.8,
                      //borderColor: "#E6E6E6",
                      //borderRadius: 7,
                      includeFontPadding: true,
                      padding: 13,
                      flex: 1
                      //padding: 17
                  }]}
                  keyboardType="numeric"
                  placeholder={"Enter Your Mobile Number"}
                  placeholderTextColor={'#9C9C9C'}
                  {...this.txtPhoneNumberProps}
                />
              </View>
              {this.state.textInputnumber == 3 &&
              (this.state.warning ? <Text style={{color:"red"}}>Blank spaces are not allowed</Text>:
              (this.state.validwarning && <Text style={{color:"red"}}>Enter valid mobile number</Text>))}
              <Text style={styles.lablestyle}>Home Town</Text>
              <TextInput
                testID={"txtAddress"}
                style={[styles.bgInput, { borderColor: bordercolor(this.state.homeTownVal)}]}
                placeholder={"Enter Your Address"}
                placeholderTextColor={'#9C9C9C'}
                maxLength={40}
                {...this.txthomeTownProps}
              />
              {(this.state.textInputnumber == 4 && this.state.warning) && <Text style={{color:"red"}}>Blank spaces are not allowed</Text>}
              <Text style={[styles.lablestyle, { marginTop: 29 }]}>Company Name</Text>
              <View style={styles.dropdownView}>
              <Dropdown
                data={this.state.companyList}
                placeholder="Select Your Company"
                value={this.state.bloodGroup}
                
                inputContainerStyle={{ borderBottomColor: 'transparent'}}
                itemPadding={10}
                placeholderTextColor={'#9C9C9C'}
                containerStyle={{
                  width:Dimensions.get("window").width/1.25,
                  height:Dimensions.get("window").height / 14,
                  top:-20
                }}
                textColor={'#rgb(1,1,1)'}
                itemTextStyle={{
                  fontSize: 19.5,
                  fontWeight: '400',
                }}
                
                fontSize={14.5}
                //@ts-ignore
                testID={'dropcompany'}
                //@ts-ignore
                onChangeText={(value) => {
                  this.setState({ companyName: value, companyNameVal: false })
                }}
              />
              </View>
              {this.state.textInputnumber == 5 && <Text style={{color:"red", padding:10}}>Please select company</Text>}
              <Button
                testID="btnSignUp"
                buttonStyle={[styles.signUpbtn, { marginTop: height / 30 }]}
                containerStyle={{ paddingVertical: 10 }}
                onPress={() => this.onPressSignUp.bind(this)()}
                title="Sign Up"
                titleStyle={{ color: 'rgb(35,1,0)', fontSize: 20, fontWeight: '400' }}
              />
              <Modal transparent visible={isVisible}>
                {/* isVisible */}
                <View style={{position:'absolute',top:0,bottom:0,right:0,left:0,backgroundColor:"black",opacity:0.8}}/>
                <KeyboardAvoidingView
                  behavior={this.isPlatformiOS() ? "padding" : undefined}
                  style={{flex:1, justifyContent:"center", alignItems:"center"}}
                >
                <View style={styles.Modalcontainer}>
                  <TouchableOpacity
                    testID="modelbtn1"
                    onPress={this.onPressCross}
                    style={{ alignSelf: 'flex-end' }}>
                    <Image
                      style={styles.Crosslogo}
                      source={Cross} />
                  </TouchableOpacity>
                  
                  <Text style={[styles.lablestyle,]}>Blood Group</Text>
                  <View style={{
                      backgroundColor: "#F7F7F7",
                      borderWidth: 0.8,
                    borderColor: bordercolor(this.state.bloodGroupVal),
                      borderRadius: 7,
                    overflow:'hidden',
                    height:Dimensions.get("window").height / 14,
                     marginTop:5,
                      }}>
                    <Dropdown
                      data={Dropdowndata}
                      placeholder="Select Your Blood Group"
                      value={this.state.bloodGroup}
                    inputContainerStyle={{ borderBottomColor: 'transparent' }}
                      itemPadding={10}
                    placeholderTextColor={'#9C9C9C'}
                      containerStyle={{
                        backgroundColor: "#F7F7F7",
                      top:-20,
                      width:Dimensions.get("window").width/1.62,
                      right:-13
                      }}
                    textColor={'#rgb(1,1,1)'}
                      itemTextStyle={{
                        fontSize: 19.5,
                      fontWeight: '400'}}
                      fontSize={14.5}
                      //@ts-ignore
                      testID={'drop1'}
                      //@ts-ignore
                    onChangeText={(value)=>{
                      this.setState({ bloodGroup: value, bloodGroupVal:false })
                    }}
                    />

                  </View>

                  <Text style={[styles.lablestyle,]}>Educational Qualification</Text>
                  <TextInput
                    testID={"Qualification"}
                    style={[styles.bgInput, { borderColor: bordercolor(this.state.eQualificationVal)}]}
                    placeholder="Enter Your Qualification"
                    placeholderTextColor={'#9C9C9C'}
                    maxLength={30}
                    {...this.txteQualificationPrpos} //Merge Engine::From BDS - {...this.testIDProps}
                  />
                  {(this.state.textInputnumber == 6 && this.state.warning) && <Text style={{color:"red"}}>Blank spaces are not allowed</Text>}


                  <Text style={[styles.lablestyle,]}>Date Of Joining</Text>

                  <View style={{
                      backgroundColor: "#F7F7F7",
                    // width: '100%',
                      marginTop: 5,
                      borderWidth: 0.8,
                    borderColor: bordercolor(this.state.dateOfJoiningVal),
                      borderRadius: 7,
                      padding: 5,
                    overflow: 'hidden'
                  }}>
                    <DatePicker
                      mode="date"
                      date={dateOfJoining}
                      format="DD-MM-YYYY"
                      confirmBtnText="Confirm"
                      cancelBtnText="Cancel"
                      placeholder="Select Date"
                      iconSource={CalenderIcon}
                      maxDate={new Date()}
                      style={{
                        color: "#9C9C9C",
                        fontSize: 14.5,
                        fontWeight: '400',
                        width: width ,
                        right: width / 2.9,
                        padding: 2,
                      }}
                      customStyles={{
                        dateInput: {
                          backgroundColor: "#F7F7F7",
                          borderColor: "#F7F7F7",
                        },
                        dateIcon: {
                          width: 27,
                          height: 27
                        },
                        placeholderText: {
                          color: "#9C9C9C"
                        },
                      }}
                      //@ts-ignore
                      testID={'drop2'}
                      //@ts-ignore
                      onDateChange={(date) => {
                        this.setState({ dateOfJoining: date, dateOfJoiningVal: false })}}
                    />
                  </View>

                  <Text style={[styles.lablestyle,]}>Date Of Birth</Text>
                  <View style={{
                      backgroundColor: "#F7F7F7",
                    // width: '100%',
                      marginTop: 5,
                      borderWidth: 0.8,
                    borderColor: bordercolor(this.state.dateOfbirthVal),
                      borderRadius: 7,
                      padding: 5,
                    overflow: 'hidden'
                  }}>
                    <DatePicker
                      mode="date"
                      date={dateOfbirth}
                      format="DD-MM-YYYY"
                      confirmBtnText="Confirm"
                      cancelBtnText="Cancel"
                      placeholder="Select Date"
                      iconSource={CalenderIcon}
                      maxDate={this.getMaxDate()}
                      style={{
                        color: "#9C9C9C",
                        fontSize: 14.5,
                        fontWeight: '400',
                        width: width ,
                        right: width / 2.9,
                        padding: 2,
                      }}
                      customStyles={{
                        dateInput: {
                          backgroundColor: "#F7F7F7",
                          borderColor: "#F7F7F7",
                        },
                        dateIcon: {
                          width: 27,
                          height: 27
                        },
                        placeholderText: {
                          color: "#9C9C9C"
                        }
                      }}
                      //@ts-ignore
                      testID={'drop3'}
                      //@ts-ignore
                      onDateChange={(date) => {
                        this.setState({ dateOfbirth: date, dateOfbirthVal: false }
                        )}}
                    />
                  </View>

                  <Button
                    buttonStyle={[styles.signUpbtn, { marginTop: Dimensions.get("window").height / 19, height: Dimensions.get("window").height / 15, width: '100%', alignSelf: 'center' }]}
                    containerStyle={{ paddingVertical: 10 }}
                    onPress={() => { this.ModalDonebtn() }}
                    title="Save"
                    testID={'Msave'}
                    titleStyle={{ color: 'rgb(35,1,0)', fontSize: 22, fontWeight: '400' }}
                  />
                </View>
                </KeyboardAvoidingView>
              </Modal>
            </View>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

  // Customizable Area Start
  //async componentDidMount() {
  // Customizable Area Start
  //this.getValidations();
  // Customizable Area End
  //}
  // Customizable Area End
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 22,
    backgroundColor: "rgb(255,255,255)"
  },
  Crosslogo: {
    width: 30,
    height: 30,
  },
  signUpText: {
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: "bold",
    color: "#rgb(1,1,1)",
    textAlign: 'center',
    marginTop: Dimensions.get("window").height / 100
  },
  signUpbtn: {
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: '#FFCC01',
    //width: width / 1.085,
    height: height / 15
  },
  lablestyle: {
    marginTop: 15,
    color: "#rgb(1,1,1)",
    fontWeight: "700",
    fontSize: 14,
  },
  bgInput: {
    // color: "#9C9C9C",
    color: "#rgb(1,1,1)",
    flexDirection: "row",
    fontSize: 14.5,
    fontWeight: '400',
    textAlign: "left",
    backgroundColor: "#F7F7F7",
    marginTop: 5,
    borderWidth: 0.8,
    borderColor: "#E6E6E6",
    borderRadius: 7,
    includeFontPadding: true,
    padding: 13
  },
  dropdownView:{
    flexDirection: "row",
    backgroundColor: "#F7F7F7",
    borderWidth: 0.8,
    borderColor: "#E6E6E6",
    borderRadius: 7,
    height:Dimensions.get("window").height / 14,
    marginTop:5,
    paddingLeft:10
  },
  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  keyboardPadding: {
    flex: 1
  },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  Modalcontainer: {
    backgroundColor: '#rgb(255,255,255)',
    padding: 20,
    width: '80%',
    alignSelf: 'center',
    borderRadius: 7,
    // flex:1,
    // margin:80

  },


  // Customizable Area End
});
