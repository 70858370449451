Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultilevelApproval";
exports.labelBodyText = "MultilevelApproval Body";

exports.multilevelTemplateApiContentType = "application/json";
exports.labelMultilevelTemplateBodyText = "Multilevel Template";
exports.labelTemplateName="Template Name";
exports.placeholderTemplateName="Enter Your Template Name";
exports.labelCompanyId="Comapny Id";
exports.placeholderCompanyId="Enter Your Comapny Id";
exports.labelOthers="Description";
exports.placeholderOthers="Description";
exports.errorTextTemplateName="Template Name is Required";
exports.errorTextCompanyId="Company Id is Required";
exports.btnExampleTitle = "CLICK ME";
exports.createTemplateApiEndPoint = "/bx_block_gamification/multi_level_approvals/create_template";
exports.createTemplateApiMethod="POST";
exports.btnLoginTitle = "Login";
exports.emailTextInputPlaceholder = "Enter your email address";
exports.passwordTextInputPlaceholder = "Enter your password";
exports.loginScreenTitle = "Login Screen";
exports.loginAPIEndPoint = "endpoint";
exports.loginAPIMethod = "POST";
exports.dashboard = "Dashboard";
// Customizable Area End
