import React, { FunctionComponent } from "react";
import {
  StyleSheet,
  Text,
  Image,
  View,
  Dimensions
} from "react-native";
//@ts-ignore
import { SliderBox } from "react-native-image-slider-box";
import { Button } from "react-native-elements";
import Scale from "../../../components/src/Scale";
const { width, height } = Dimensions.get('window');
interface MyProps {
  splashScreen2:boolean;
  onPressSignUp:any;
  onPressSignIn:any;
  imgSplash:any;
  SliderImage:any;
}

const SplashView: FunctionComponent<MyProps> = (props) => {
  return (
    <>
    {props.splashScreen2?
          <View style={styles.logoView}>
            <Image source={props.imgSplash} style={{width: 270, height: 270 }} />
          </View>
          :
          <View style={styles.splashScreen2}>
            <Text style={styles.logoText}>Welcome!</Text>
            <View style={styles.ImageSliderView}>
              <SliderBox
                images={[
                  props.SliderImage,
                  props.SliderImage,
                  props.SliderImage,
                ]}
                sliderBoxHeight={2}
                dotColor="#FFCC01"
                inactiveDotColor="#90A4AE"
                dotStyle={styles.dotstyle}
                autoplay
                circleLoop
                paginationBoxVerticalPadding={1}
                resizeMethod={'resize'}
                resizeMode={'cover'}
                ImageComponentStyle={{
                  width:250,
                  resizeMode: 'cover',
                  height:150,
                  }}
              />
              <View style={styles.txtimageslider}>
                <Text style={styles.imageTxt}>Now Track Your Daily Performance</Text>
                <Text style={styles.imageTxt}>Easily With Us!</Text>
              </View>
            </View>
            <Button
              buttonStyle={styles.signUpbtn}
              containerStyle={{ paddingVertical: 10 }}
              onPress={props.onPressSignUp}
              title="Sign Up"
              titleStyle={{ color: 'rgb(35,1,0)', fontSize: Scale(22), fontWeight: '400' }}
            />

            <View style={styles.orTxand_lineCnt}>
              <View style={styles.or_line}></View>
              <Text style={styles.Or_txt}>Or</Text>
              <View style={styles.or_line}></View>
            </View>
            <Button
              buttonStyle={styles.signInbtn}
              containerStyle={{ paddingVertical: 10 }}
              onPress={props.onPressSignIn}
              titleStyle={{ color: 'rgb(241,252,255)', fontSize: Scale(22), fontWeight: '400' }}
              title="Sign In"
            />
          </View>
        }
    </>
  )
}

const styles = StyleSheet.create({
  logoView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  logoText: {
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: "bold",
    color: "#rgb(1,1,1)",
    marginTop: height/9,

  },
  splashScreen2: {
    alignItems: "center",
  },
  ImageSliderView: {
    marginTop: 35,
    paddingVertical: '2%',
    height: 300,
  },
  dotstyle: {
    width: 10,
    height: 10,
    borderRadius: 15,
    marginHorizontal: -3.5,
    padding: 0,
    margin: 0
  },
  txtimageslider: {
    position: 'absolute',
    bottom: '15%',
    alignSelf: 'center'
  },
  imageTxt: {
    textAlign: 'center',
    color: 'rgb(35,1,0)',
    fontSize: 18.5,
    fontWeight: '500'
  },
  signUpbtn: {
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: '#FFCC01',
    width: width / 1.085,
    height: height / 15
  },
  signInbtn: {
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: '#36474F',
    width: width / 1.085,
    height: height / 15
  },
  orTxand_lineCnt: {
    flexDirection: 'row',
    alignItems: 'center',
    width: width / 1.085,
    justifyContent: 'space-between',
    marginVertical: height / 200
  },
  or_line: {
    borderBottomColor: "#rgb(1,1,1)",
    borderStyle: 'solid',
    borderBottomWidth: 1,
    width: '43%'
  },
  Or_txt: {
    color: "#rgb(1,1,1)",
    fontSize: 16,
    fontWeight: '700'
  }

});

export default SplashView;