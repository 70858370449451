import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { BackHandler } from 'react-native';
import moment from "moment";
let calendarDate = moment();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  initDate:any;
  markedDates:any,
  disabledDaysIndexes:number[],
  month:string[],
  calendarDate:any;
  colorStatus:any;
  isVisible:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.onPressArrowLeft = this.onPressArrowLeft.bind(this);
    this.onPressArrowRight = this.onPressArrowRight.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      initDate : new Date(),
      markedDates:[],
      disabledDaysIndexes:[5,6],
      month:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      calendarDate: moment().format('YYYY-MM-DD'),
      colorStatus:[{color:'#00BEA0',text:'Submitted'},{color:'#C3C4C6',text:'Not Assigned'},{color:'#FF5C5C',text:'Pending'}],
      isVisible:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getDisabledDays(this.state.initDate);
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonTaskList);
    // Customizable Area End
  }

  async componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonTaskList);
  }
  
  handleBackButtonTaskList =()=>{
  this.props.navigation.replace("BottomTabNavigator")
   return true;
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getDisabledDays = (date:any) => {
    let dates:any = {};  
    const disabled = { disabled: true, disableTouchEvent: true };
    const holiday = {marked: true, dotColor: '#FF5C5C',}
    const off = {marked: true, dotColor: '#00BEA0',}
    const today = { customStyles:{
        container: {
          backgroundColor: '#FFCC00',
          paddingTop:2
        },
        text: {
          color: '#fff',
          fontFamily: 'OpenSans-Bold',
        }
      }
    }
    const leave = {marked: true, dotColor: '#C3C4C6',}
    let start = moment(new Date(date)).startOf('month');
    let temp = moment(new Date(date)).startOf('month');
    let end1 = moment(new Date(date)).endOf('month');
    let start2 = moment('2023-06-07')
    let end2 = moment('2023-06-21')
    for(let i=0;i<=end1.diff(temp, 'days');i++){
      if(start.isSameOrAfter(start2)&&start.isSameOrBefore(end2))
      {
        if(start.day()==0||start.day()==6)
        dates[moment(start).format('YYYY-MM-DD')] = {...disabled,...leave};
        else
        dates[moment(start).format('YYYY-MM-DD')] = leave;
        start.add(1, 'days');
        continue;
      }
      if(start.day()==0||start.day()==6)
      dates[moment(start).format('YYYY-MM-DD')] = disabled;
      start.add(1, 'days');
    }
    dates[moment(this.state.initDate).format('YYYY-MM-DD')] = today
    dates[moment('2023-06-05').format('YYYY-MM-DD')] = holiday 
    dates[moment('2023-06-01').format('YYYY-MM-DD')] = off
    this.setState({markedDates:dates})
    return dates;
  };

  onPressArrowLeft(){
    calendarDate = moment(calendarDate).add(-1, 'month');
    console.log("kdshk");
    
    this.updateCalendarDate();
  }

  onPressArrowRight() {
    calendarDate = moment(calendarDate).add(1, 'month');
    this.updateCalendarDate();
  }

  updateCalendarDate() {
    this.setState({
      calendarDate: moment(calendarDate).format('YYYY-MM-DD')
    });
  }

  onMonthChange=(date:any)=>{
    this.getDisabledDays(new Date(date.timestamp))
  }

  onDayPress=(day:any)=>{
    console.log('selected day', day)
    this.setState({isVisible:true})
  }

  onPressClose=()=>{
    this.setState({isVisible:false})
  }
  // Customizable Area End
}
