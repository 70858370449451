import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  SafeAreaView,
  Dimensions
} from "react-native";
import SplashView from "./SplashView";
import { SliderImage } from "./assets";
// Customizable Area End

import SplashscreenController, { Props } from "./SplashscreenController";

import { imgSplash } from "./assets";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <SafeAreaView style={styles.mainContainer}>
        {/* Customizable Area Start */}
        <SplashView
        splashScreen2={this.state.splashScreen2}
        onPressSignUp={this.onPressSignUp}
        onPressSignIn={this.onPressSignIn}
        SliderImage={SliderImage}
        imgSplash={imgSplash}
        />
        {/* Customizable Area End */}
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "rgb(255,255,255)"
  },
});
// Customizable Area End
