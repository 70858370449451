import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { BackHandler, Alert } from 'react-native';
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { Country } from './types'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  homeTown: string;
  warning:boolean;
  validwarning:boolean;
  textInputnumber:number;
  countryCode: any;
  country: any;
  withCountryNameButton: any;
  withFlag: any;
  withEmoji: any;
  withFilter: any;
  withAlphaFilter: any;
  withCallingCode: any;
  isVisible: boolean,

  firstNameVal: boolean,
  emailVal: boolean,
  phoneVal: boolean,
  homeTownVal: boolean

  companyName: any;
  companyId:any;
  companyIdVal:boolean;
  bloodGroup: any;
  eQualification: any;
  dateOfJoining: any;
  dateOfbirth: any;
  companyNameVal: boolean;
  bloodGroupVal: boolean;
  eQualificationVal: boolean;
  dateOfJoiningVal: boolean;
  dateOfbirthVal: boolean;
  id:number;
  companyList:any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  SignUpCreateUSerApiCallId: any
  updateDetailApiCallId: any
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  getCompanyAPICallId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      homeTown: '',
      countryCode: 'IN',
      country: ["91"],
      withCountryNameButton: false,
      withFlag: true,
      withEmoji: true,
      withFilter: true,
      withAlphaFilter: false,
      withCallingCode: true,
      isVisible: false,
      firstNameVal: false,
      emailVal: false,
      phoneVal: false,
      homeTownVal: false,
      companyName: '',
      companyId:'',
      companyIdVal:false,
      bloodGroup: '',
      eQualification: '',
      dateOfJoining: '',
      dateOfbirth: '',
      companyNameVal: false,
      bloodGroupVal: false,
      eQualificationVal: false,
      dateOfJoiningVal: false,
      dateOfbirthVal: false,
      id:0,
      warning:false,
      textInputnumber:0,
      validwarning:false,
      companyList:[]
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = 'Enter Your Name';
    this.lastName = configJSON.lastName;
    this.labelEmail = 'Enter Your Email ID';
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }
 // Customizable Area Start

 onPressCross=()=>{
  this.setState({isVisible:false})
  this.props.navigation.navigate("BottomTabNavigator");
}
validfirstname=()=>{
  const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
  const numbers= /\d/
  const emojis= /\p{Emoji}/u
  const isSpecialCharsPresent = specialChars.split('').some(char => this.state.firstName.includes(char))
      if (this.state.firstName == "") {
        this.setState({ firstNameVal: true })
        Alert.alert("Enter First name")
        return false
      }
      else if(this.state.firstName.length < 3){
        this.setState({ firstNameVal: true })
        Alert.alert("Enter atleast three characters in first name")
        return false
      }
      else if(isSpecialCharsPresent ){
        Alert.alert("Remove special characters in first name")
        this.setState({ firstNameVal: true })
        return false
      }
      else if(numbers.test(this.state.firstName)){
        Alert.alert("Remove numbers in first name")
        this.setState({ firstNameVal: true })
        return false
      }
      else if(emojis.test(this.state.firstName)){
        Alert.alert("Remove emojis in first name")
        this.setState({ firstNameVal: true })
        return false
      }
      else if(this.state.firstName.trim() !== this.state.firstName || /\s{2,}/.test(this.state.firstName)){
        this.setState({ firstNameVal: true })
        Alert.alert("Remove blank spaces in First name")
        return false
      } 
      else{
        this.setState({firstNameVal:false})
        return true
      }
}

validemail=()=>{
  const mailformat = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
  if(this.state.email == ""){
    this.setState({ emailVal: true })
    Alert.alert("Enter email")
    return false
  }
  else if(this.state.email.trim() !== this.state.email || /\s{2,}/.test(this.state.email)){
    this.setState({ emailVal: true })
    Alert.alert("Remove blank spaces in email")
    return false
  }
  else if (!this.state.email.match(mailformat)) {
    this.setState({ emailVal: true })
    Alert.alert("Enter valid email")
    return false
  }
  else if(this.state.email.startsWith(this.state.email.charAt(0).toUpperCase())){
    Alert.alert("Enter valid email")
    this.setState({ emailVal: true })
    return false
  }
  else{
    this.setState({emailVal:false})
    return true
  }
}

validphone=()=>{
  let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  if (this.state.phone ==''){
    this.setState({ phoneVal: true })
    Alert.alert("Enter phone number")
    return false
  }
  else if (this.state.phone[0]=='0'){
    this.setState({ phoneVal: true })
    Alert.alert("Enter valid phone number")
    return false
  }
   else if (!re.test(this.state.phone)) {
    this.setState({ phoneVal: true })
    Alert.alert("Enter valid phonenumber")
    return false
  } 
  else if(this.state.phone.trim() !== this.state.phone || /\s{2,}/.test(this.state.phone)){
    this.setState({ phoneVal: true })
    Alert.alert("Remove blank spaces in phone number")
    return false
  }
  else{
    this.setState({phoneVal:false})
    return true
  }
}
validhometown=()=>{
  const numbers= /\d/
  const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
  const isSpecialChar = specialChars.split('').some(char => this.state.homeTown.includes(char))
  const emojis= /\p{Emoji}/u
  if (this.state.homeTown == '') {
    this.setState({ homeTownVal: true })
    Alert.alert("Enter home town")
    return false
  }
  else if(this.state.homeTown.length < 3){
    this.setState({ homeTownVal: true })
    Alert.alert("Enter atleast three characters in home town")
    return false
  }
  else if(this.state.homeTown.trim() !== this.state.homeTown || /\s{2,}/.test(this.state.homeTown)){
    this.setState({ homeTownVal: true })
    Alert.alert("Remove blank spaces in home town")
    return false
  }
  else if(numbers.test(this.state.homeTown)){
    Alert.alert("Remove numbers in home town")
    this.setState({ homeTownVal: true })
    return false
  }
  else if(emojis.test(this.state.homeTown)){
    Alert.alert("Remove emojis in home town")
    this.setState({ homeTownVal: true })
    return false
  }
  else if(isSpecialChar ){
    Alert.alert("Remove special characters in home town")
    this.setState({ homeTownVal: true })
    return false
  }
  else{
    this.setState({homeTownVal:false})
    return true
  }
}
validCompany=()=>{
  if (this.state.companyName == "") {
    this.setState({ textInputnumber: 5 })
    Alert.alert("Select company name")
    return false
  }
  else{
      this.setState({textInputnumber:0})
      return true;
  }
}
  onPressSignUp = () => {

    if(this.validfirstname()&& this.validemail() && this.validphone() && this.validhometown() && this.validCompany()){
      
      let fullNumber = "+" + this.state.country[0] + this.state.phone

      console.log('*** Success ***');
      console.log('Name :', this.state.firstName);
      console.log('Email:', this.state.email);
      console.log('Phone:', fullNumber);
      console.log('Add..:', this.state.homeTown);
      console.log('Company:', this.state.companyName);
      console.log('-------');
      console.log('');
      this.funSignUpCreateUSerApiCallId('cross')
    }
  }

  funSignUpCreateUSerApiCallId = async (type:any) => {
    const { firstName, email, country, phone, homeTown, companyName} = this.state
    const header = {
      "Content-Type": configJSON.contentType,
      //token,
    };

    const body = {
      "data": {
        "attributes":
        {
          "name": firstName,
          "email": email,
          "full_phone_number" : "+" + country[0]+phone,
          "password" : configJSON.detail,
          "activated" : "true",
          "home_town" : homeTown,
          "company_name" : companyName
        }
      }

    }
    console.log('-----------------------');
      console.log(body);
    console.log('-----------------------');

    const enpointUrl: any = `account_block/accounts`;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SignUpCreateUSerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      enpointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }


  ModalDonebtn = () => {
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => 
      this.state.eQualification.includes(char))
    if (this.state.bloodGroup == '') {
      this.setState({ bloodGroupVal: true })
    } else if(this.state.eQualification == ''){
      this.setState({ eQualificationVal: true })
    }else if(this.state.eQualification.length == 1){
      this.setState({ eQualificationVal: true })
    }else if(this.state.eQualification.trim() !== this.state.eQualification){
      this.setState({ eQualificationVal: true })
    } else if(/\s{2,}/.test(this.state.eQualification)){
      this.setState({ eQualificationVal: true })
    } else if(isSpecialCharsPresent ){
      this.setState({ eQualificationVal: true })
    }else if(numbers.test(this.state.eQualification)){
      this.setState({ eQualificationVal: true })
    }else if(emojis.test(this.state.eQualification)){
      this.setState({ eQualificationVal: true })
    }else if(this.state.dateOfJoining==''){
      this.setState({ dateOfJoiningVal: true })
    }else if(this.state.dateOfbirth==''){
      this.setState({ dateOfbirthVal: true })
    }else {
      console.log('');
      console.log('B Group :', this.state.bloodGroup);
      console.log('E Quali :', this.state.eQualification);
      console.log('DateJoi :', this.state.dateOfJoining);
      console.log('DOB  ** :', this.state.dateOfbirth);
      this.funupdateDetailApiCallId()
    }
  }

  funupdateDetailApiCallId = async () => {
    const { id, bloodGroup, eQualification, dateOfJoining, dateOfbirth } = this.state
    
    const token = (await getStorageData("authToken", false)) || "";
    const header = {
      "Content-Type": configJSON.contentTypeformdata,
      token,
    };
    let formdata = new FormData();
    formdata.append("blood_group",bloodGroup)
    formdata.append("higher_education",eQualification)
    formdata.append("date_of_birth",dateOfbirth)
    formdata.append("date_of_joining",dateOfJoining)

    const enpointUrl: any = 'account_block/accounts/'+id;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      enpointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompanyAPICall = async () => {
    const enpointUrl: any = 'bx_block_gamification/show_company';

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyAPICallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      enpointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount()  {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
    this.getCompanyAPICall()
  }

  async componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
  }

  handleBackButton = () => {
    Alert.alert(
      'Exit App',
      'Exiting the application?', [{
        text: 'Cancel',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel'
      }, {
        text: 'OK',
        onPress: () => BackHandler.exitApp()
      },], {
      cancelable: false
    }
    )
    return true;
  }
  getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return maxDate;
  };

  onSelect = (country: Country) => {
    this.setState({ countryCode: country.cca2 })
    this.setState({ country: country.callingCode })
  }
// Customizable Area End
  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text, validwarning:false});
      this.setState({ emailVal: false });
      if(text.trim() !== text || text == "" || /\s{2,}/.test(text)){
      this.setState({textInputnumber:2, warning:true})
      }else{
        this.setState({ warning: false });
      }
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text});
      this.setState({ phoneVal: false });
      
      if(text.trim() !== text || /\s{2,}/.test(text)){
      this.setState({textInputnumber:3, warning:true})}
      else{
      this.setState({warning:false})}
      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txthomeTownWebProps = {
    onChangeText: (text: string) => {
      this.setState({ homeTown: text });
      this.setState({ homeTownVal: false });
      if(text.trim() !== text || /\s{2,}/.test(text)){
      this.setState({textInputnumber:4, warning:true})}
      else{
      this.setState({warning:false})}
      //@ts-ignore
      this.txthomeTownWebProps.value = text;
    }
  };

  txthomeTownMobileProps = {
    ...this.txthomeTownWebProps,
  };

  txthomeTownProps = this.isPlatformWeb()
    ? this.txthomeTownWebProps
    : this.txthomeTownMobileProps;



  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
      this.setState({ firstNameVal: false });
      if(text.trim() !== text || /\s{2,}/.test(text)){
      this.setState({textInputnumber:1, warning:true})}
      else{
      this.setState({warning:false})}
      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;

    }
  };

  txteQualificationPrpos = {
    onChangeText: (text: string) => {
      this.setState({ eQualification: text });
      this.setState({ eQualificationVal: false });
      if(text.trim() !== text || /\s{2,}/.test(text)){
      this.setState({textInputnumber:6, warning:true})}
      else{
      this.setState({warning:false})}
      //@ts-ignore
      this.txteQualificationPrpos.value = text;

    }
  };

  SignUpUserDataHandler = (responseJson: any) => {
    if (!responseJson) {
      Alert.alert('Something went wrong, please try again later.!!')
    }
    else if (responseJson.data) {
      console.log('SignUpCreateUSerApiCallId Success');
      Alert.alert("Success", responseJson.meta.message, [{
        text: "ok", onPress: () => {
          this.setState({ isVisible: true, id: responseJson.data.id })
        }
      }])
      // alert(responseJson.meta.message)
      setStorageData('authToken', responseJson.meta.token);
      setStorageData("user_id", responseJson.data.id);
    } else if (responseJson.errors) {
      console.log('SignUpCreateUSerApiCallId error');
      Alert.alert(responseJson.errors[0].account)
    }
  }

  UpdateDetailDataHander = (responseJson: any) => {
    if (!responseJson){
    Alert.alert('Something went wrong, please try again later.!!')}
    else if (responseJson.data) {
      console.log('updateDetailApiCallId Success');
      this.setState({ isVisible: false })
      this.props.navigation.replace("BottomTabNavigator")
    }
  }

  getCompanyDataHandler = (responseJson: any) => {
    if (!responseJson){
    Alert.alert('Something went wrong, please try again later.!!')}
    else if (responseJson) {
      console.log('getCompanyAPICallId Success', responseJson);
      const nameArray = responseJson.map((item: any) => item.name)
      const transformedArray = nameArray.map((name: any) => ({ value: name }));
      this.setState({ companyList: transformedArray })
    }
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.SignUpCreateUSerApiCallId) {
        this.SignUpUserDataHandler(responseJson)
      }
      else if (apiRequestCallId === this.updateDetailApiCallId) {
        this.UpdateDetailDataHander(responseJson)
      }
      else if (apiRequestCallId === this.getCompanyAPICallId) {
        this.getCompanyDataHandler(responseJson)
      }
    }
    // Customizable Area End
  }
}
