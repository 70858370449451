import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { careerLadderChart, dailyKpiReport, kpiGamecard, selfKPIReview, statistics } from "./assets";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  gridListView: boolean
  profileData:{
    profileImage: string,
    fullName: string,
    designation:string,
    firstName:string,
    notificationCount: number,
    progressPer: number
    
  }
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  time = new Date().getHours();
  dashboardOptions = [
    {
      name:"Daily KPI Report",
      icon:dailyKpiReport,
      screenName: "DailyKpiReport"
    },
    {
      name:"KPI Gamecard",
      icon:kpiGamecard,
      screenName: "KPIGamecard"
    },
    {
      name:"Statistics",
      icon:statistics,
      screenName: "Statistics"
    },
    {
      name:"Career Ladder Chart",
      icon:careerLadderChart,
      screenName: "CareerLadder"
    },
    {
      name:"Self KPI Review",
      icon:selfKPIReview,
      screenName: "SelfKPIReview"
    }
  ]
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      gridListView: true,
      profileData:{
        profileImage:'',
        fullName:'',
        designation:'',
        firstName:'',
        notificationCount: 0,
        progressPer: 0
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    let tempToken  = await getStorageData('authToken');
    this.setState({token: tempToken}, ()=> {
      this.getDashboardData();
    })
    this.props.navigation.addListener('didFocus', () => {
      this.getDashboardData();
    });
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiDashboardItemCallId) {
        this.getProfileDataHandler(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getProfileDataHandler = (responseJson:any) =>{
    if (responseJson){
      const tempProfileData = {
        profileImage:responseJson.account_details.profile_pic,
        fullName:responseJson.account_details.full_name,
        designation:responseJson.account_details.designation,
        firstName:responseJson.account_details.first_name,
        notificationCount: responseJson.notification_count,
        progressPer: Number(responseJson.progress_bar.progress_per)
      }
      this.setState({ loading: false, profileData:tempProfileData })
    }else{
      this.setState({ loading: false })
    }
  }

  changeView=()=>{
    this.setState({ gridListView: !this.state.gridListView })
  }

  gotoNotifications=()=>{
    this.navigateToScreen('Notifications',null)
  }
  onProfilePress = () => {
    this.navigateToScreen('CustomisableUserProfiles',null)
  }
  navigateToScreen = (screenName: string, props:any) => {
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    msgs.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), props);
    msgs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgs)
  }

  // Customizable Area End

}
