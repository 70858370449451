import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  Dimensions,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  TextInput,
  Image
} from "react-native";

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { Button } from "react-native-elements";
import { Forfotlogo, ForfotlogoBlck } from './assets'
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            this.isPlatformWeb() ? styles.containerWeb : styles.containerMobile
          }
        >
          <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
            {/* Customizable Area Start */}
            <View>
              {/* headline */}
              <Image
                source={!this.state.EmailOrNumberValid ? Forfotlogo : ForfotlogoBlck}
                style={styles.logoStyle}
              />
              {/* <Text style={styles.titleWhySignUp}>Logo</Text> */}
              <Text style={styles.titleWhySignUp}>Forgot Password</Text>
              <Text style={styles.titleline2}>Enter Your Email For The Verification</Text>
              <Text style={styles.titleline2}>Process, We Will Send You 5 Digit Code To Your Email</Text>
              <View style={{ padding: 16 }}>
                <Text style={[styles.lablestyle, { marginTop: 40 }]}>Email ID</Text>
                <TextInput
                  testID="txtInputEmail" //Merge Engine::From BDS
                  style={styles.bgMobileInput} //UI Engine::From Sketch
                  placeholderTextColor={'#9C9C9C'}
                  placeholder="Enter Your Email ID" //UI Engine::From Sketch
                  {...this.txtInputEmailProps} //Merge Engine::From BDS - {...this.testIDProps}
                />
                <Button
                testID="btn2"
                  buttonStyle={[styles.signUpbtn, { marginTop: Dimensions.get("window").height / 15, backgroundColor: this.state.EmailOrNumberValid ? '#FFCC01' : "#E6E6E6" }]}
                  containerStyle={{ paddingVertical: 10 }}
                  onPress={this.SendOtp}
                  title="Send OTP"
                  titleStyle={{ color: 'rgb(35,1,0)', fontSize: 22, fontWeight: '400' }}
                />

              </View>
            </View>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "rgb(255,255,255)"
  },
  logoStyle: {
    width: 85,
    height: 105,
    alignSelf: 'center',
    marginTop: Dimensions.get("window").height / 11

  },
  titleWhySignUp: {
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: "bold",
    color: "#rgb(1,1,1)",
    textAlign: 'center',
    marginTop: Dimensions.get("window").height / 51
  },
  titleline2: {
    color: "#9C9C9C",
    fontSize: 12.2,
    fontWeight: 'bold',
    textAlign: "center",
    width: Dimensions.get("window").width / 1,
    marginTop: 10,
  },
  lablestyle: {
    marginTop: 15,
    color: "#rgb(1,1,1)",
    fontWeight: "700",
    fontSize: 14,
    //padding: 16,
  },
  bgMobileInput: {
    // color: "#9C9C9C",
    color: "#rgb(1,1,1)",
    flexDirection: "row",
    fontSize: 14.5,
    fontWeight: '400',
    textAlign: "left",
    backgroundColor: "#F7F7F7",
    marginTop: 5,
    borderWidth: 0.8,
    borderColor: "#E6E6E6",
    borderRadius: 7,
    includeFontPadding: true,
    padding: 13
  },
  signUpbtn: {
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: '#FFCC01',
    //width: width / 1.085,
    height: Dimensions.get("window").height / 15
  },
  ///////////


  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650
  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center"
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%"
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1
  },

  mobileInput: {
    flexDirection: "column",
    alignItems: "stretch",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true
  },

  codeInput: {
    marginTop: 20,
    width: "30%"
  },

  phoneInput: {
    flex: 3,
    marginTop: 20
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    color: "red",
    textAlign: "center"
  }
});
//Customizable Area End

