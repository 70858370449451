import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeout: any;
  splashScreen2: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
       timeout: 2000
       // Customizable Area Start
       ,splashScreen2:true
       // Customizable Area End
    }
    
    // Customizable Area Startg
    this.subScribedMessages = [];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   async componentDidMount() {
     super.componentDidMount();
     setTimeout(() => {
      this.gotoNextScreen()
     }, 2000);
   }

   gotoNextScreen=async()=>{
    const login = await getStorageData('authToken');
    if(login)
      this.props.navigation.replace("BottomTabNavigator")
    else
      this.setState({splashScreen2:false})
   }

   onPressSignIn = () => {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.EmailLogInMessageData),{});
      this.send(msg);
    }
   onPressSignUp = () => {
      const msg: Message = new Message(
        getName(MessageEnum.NavigateEmailSignUpMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.EmailSignUpMessageData),{});
      this.send(msg);
    }

  // Customizable Area End
}
