import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import { BackHandler, Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  // labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  remainingTime: number;
  otpEnter: boolean;
  verifyOtpcheck: boolean;
  isVisible: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  // labelInfo: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  otpTimer: any;
  otpWaitTime: number = 25;
  OtpVerifyApiCallId: any;
  SendOtpfunForgot: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      // labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      remainingTime: this.otpWaitTime,
      otpEnter: false,
      verifyOtpcheck: false,
      isVisible: false
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }
  // Customizable Area Start
  submitOtp=()=>{
  }

  setOTP=(text:any)=>this.setState({ otp: text })

  handleBackButton = () => {
    Alert.alert(
      "Exit App",
      "Exiting the application?",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "OK",
          onPress: () => BackHandler.exitApp()
        }
      ],
      {
        cancelable: false
      }
    );
    return true;
  };

  async componentDidMount() {
    this.startTimer();
    BackHandler.addEventListener("hardwareBackPress", this.handleBackButton);
  }

  startTimer = async () => {
    // console.log("console -> countdown", this.otpTimer);
    const OtpEmail = (await getStorageData("OtpEmail", false)) || "";
    if (this.state.remainingTime == 0) {
      this.funSendOtpfunForgot(OtpEmail);
    }

    if (!this.otpTimer) {
      this.setState({ remainingTime: this.otpWaitTime });
      this.otpTimer = setInterval(this.countdown, 1000);
    }
  };

  countdown = async () => {
    // console.log("console -> countdown", this.state ? this.state : null);
    if (!this.state) {
      return;
    }
    const { remainingTime } = this.state;
    let rTime = remainingTime;
    if (remainingTime == 0) {
      // console.log("console -> countdown", remainingTime);
      clearTimeout(this.otpTimer);
      clearInterval(this.otpTimer);
      this.otpTimer = null;
      console.log("-");
      console.log("Count End 00:00");
      console.log("-");

      // doSomething();
    } else {
      this.setState({ remainingTime: rTime - 1 }); //we subtract the second each iteration
    }
  };

  RetypeEmail = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), {});
    this.send(msg);
  };

  otpInputHandle = (code: any) => {
    this.setState({ otpEnter: true });
    this.setState({ otp: code });
    console.log(`Code is ${code}, you are good to go!`);
  };

  VerifyOtp = () => {
    if (this.state.otp) {
      this.funOtpVerifyApiCallId();
    } else {
      this.setState({ verifyOtpcheck: true });
    }
  };

  ModalDonebtn = () => {
    this.setState({ isVisible: false });
    this.props.navigation.navigate("NewPassword")
  };

  funSendOtpfunForgot = async (email: any) => {
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType
    };

    const body1 = {
      data: {
        attributes: {
          email: email
        }
      }
    };

    console.log("-----------------------");
    console.log(body1);
    console.log("-----------------------");

    const enpointUrl: any = `bx_block_forgot_password/otps`;
    this.apiCall({
      setApiCallId: "SendOtpfunForgot",
      header,
      method: configJSON.apiVerifyOtpMethod,
      endPoint: enpointUrl,
      body:JSON.stringify(body1)
    });
  };

  funOtpVerifyApiCallId = async () => {
    const token = (await getStorageData("OtpAuthToken", false)) || "";
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType
    };

    const body = {
      data: {
        token: token,
        otp_code: this.state.otp
      }
    };
    console.log("-----------------------");
    console.log("body", body);
    console.log("-----------------------");

    const enpointUrl: any = `bx_block_forgot_password/otp_confirmations`;
    this.apiCall({
      setApiCallId: "OtpVerifyApiCallId",
      header,
      method: configJSON.apiVerifyOtpMethod,
      endPoint: enpointUrl,
      body: JSON.stringify(body)
    });
  };

  apiCall = async (data: any) => {
    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === "OtpVerifyApiCallId") {
      this.OtpVerifyApiCallId = requestMessage.messageId;
    }
    if (setApiCallId === "SendOtpfunForgot") {
      this.SendOtpfunForgot = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
  async receive(from: String, message: Message) {
    // Customizable Area Start
    let responseJson;
    if (
      this.otpAuthApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.isOTPCheck(responseJson);

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      this.OtpVerifyApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson === undefined) {
        return;
      }

      this.verifyOTPCheckfunc(responseJson);
    } else if (
      this.SendOtpfunForgot ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson === undefined) {
        return;
      }

      this.sendOTP(responseJson);
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.setData(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setData = (message: any) => {
    const phoneAuthToken = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );

    const phoneNumber = message.getData(
      getName(MessageEnum.AuthTokenPhoneNumberMessage)
    );

    const forgotPasswordBool = message.getData(
      getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
    );

    const emailValue = message.getData(
      getName(MessageEnum.AuthTokenEmailMessage)
    );

    const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

    this.setState({
      otpAuthToken:
        phoneAuthToken && phoneAuthToken.length > 0
          ? phoneAuthToken
          : this.state.otpAuthToken,
      userAccountID: userAccountID,
      isFromForgotPassword:
        forgotPasswordBool === undefined
          ? this.state.isFromForgotPassword
          : forgotPasswordBool
    });
  };

  sendOTP = (responseJson: any) => {
    if (!responseJson) {
      Alert.alert("Something went wrong, please try again later.!!");
    } else if (responseJson.meta) {
      console.log("SendOtpfunForgot Success");
      setStorageData("OtpAuthToken", responseJson.meta.token);
      this.props.navigation.navigate("OTPInputAuth");
    } else if (responseJson.errors) {
      Alert.alert(responseJson.errors[0].otp);
    }
  };

  verifyOTPCheckfunc = (responseJson: any) => {
    if (responseJson && responseJson.messages) {
      this.setState({ verifyOtpcheck: false });
      this.setState({ isVisible: true });
    } else if (responseJson.errors) {
      this.setState({ verifyOtpcheck: true });
    }
  };

  isOTPCheck = (responseJson: any) => {
    if (responseJson.meta && responseJson.meta.token) {
      this.setState({
        otpAuthToken: responseJson.meta.token
      });

      this.isFromForgotPassword();
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  };
  isFromForgotPassword = () => {
    if (this.state.isFromForgotPassword) {
      // runEngine.debugLog("about to send NavigationNewPasswordMessage");
      const msg: Message = new Message(
        getName(MessageEnum.NavigationNewPasswordMessage)
      );
      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        this.state.otpAuthToken
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
    } else {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
      );

      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        this.state.otpAuthToken
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
    }
  };

  setOtp = (text: string) => this.setState({ otp: text });

  onCodeChange = (code: any) =>
    this.setState({ otp: code, verifyOtpcheck: false });

  onCodeFill = (code: any) => this.otpInputHandle(code);
  // Customizable Area End
}
