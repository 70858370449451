import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Dimensions,
  Image
} from "react-native";

import { Button } from "react-native-elements";
import OTPInputView from "@twotalltotems/react-native-otp-input";
import Modal from "react-native-modal";
import { otpLogo, rightTick } from "./assets";
// Customizable Area End
import OTPInputAuthController, { Props } from "./OTPInputAuthController";

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { remainingTime, otpEnter, verifyOtpcheck, isVisible } = this.state;
    let color = verifyOtpcheck ? "#CCAEAD" : "#FFCC01";
    const styl = {
      ...styles.underlineStyleBase,
      borderColor: otpEnter ? color : "#E6E6E6"
    };
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View>
            <Image source={otpLogo} style={styles.logoStyle} />
            {/* <Text style={styles.titleWhySignUp}>Logo</Text> */}
            <Text style={styles.titleWhySignUp}>Enter OTP</Text>
            <Text style={styles.titleline2}>
              Enter 5 digit code sent to your email
            </Text>
            <View style={{ alignItems: "center", paddingTop: "8%" }}>
              <OTPInputView
                style={{ width: "90%", height: 70 }}
                pinCount={5}
                // code={this.state.code} //You can supply this prop or not. The component will be used as a controlled / uncontrolled component respectively.
                onCodeChanged={this.onCodeChange}
                autoFocusOnLoad={false}
                codeInputFieldStyle={styl}
                codeInputHighlightStyle={styles.underlineStyleHighLighted}
                onCodeFilled={this.onCodeFill}
              />
            </View>
            <View
              style={{
                paddingHorizontal: 18,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              {this.state.verifyOtpcheck ? (
                <Text style={[styles.otp_Text, { left: 0, color: "#FF374F" }]}>
                  Invalid OTP
                </Text>
              ) : (
                <Text />
              )}
              <View style={{ flexDirection: "row" }}>
                {remainingTime == 0 ? (
                  <Text onPress={this.startTimer} style={styles.otp_Text}>
                    Resend code
                  </Text>
                ) : (
                  <Text style={[styles.otp_Text, { color: "#9C9C9C" }]}>
                    Resend code in
                  </Text>
                )}
                {remainingTime != 0 ? (
                  <Text style={[styles.otp_Text]}>
                    {" "}
                    :{" "}
                    {`${new Date(remainingTime * 1000)
                      .toISOString()
                      .substring(15, 19)}`}
                  </Text>
                ) : null}
              </View>
            </View>
            <Button
              buttonStyle={[
                styles.signUpbtn,
                { marginTop: Dimensions.get("window").height / 15 }
              ]}
              containerStyle={{ paddingVertical: 10 }}
              onPress={this.VerifyOtp}
              title="Verify"
              titleStyle={{
                color: "rgb(35,1,0)",
                fontSize: 20,
                fontWeight: "400"
              }}
            />

            <Text
              style={[
                {
                  fontWeight: "700",
                  fontSize: 14,
                  textAlign: "center",
                  color: "#9C9C9C",
                  marginTop: 25
                }
              ]}
            >
              Wrong Email?
              <Text
                onPress={this.RetypeEmail}
                style={[
                  {
                    marginTop: 15,
                    color: "#rgb(1,1,1)",
                    fontWeight: "700",
                    fontSize: 14,
                    textAlign: "center"
                  }
                ]}
              >
                {" "}
                Retype Email
              </Text>
            </Text>

            <Modal isVisible={isVisible}>
              <View
                style={{
                  backgroundColor: "#rgb(255,255,255)",
                  height: 330,
                  width: 300,
                  alignSelf: "center",
                  borderRadius: 7,
                  padding: 10
                }}
              >
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    alignSelf: "center"
                  }}
                  source={rightTick}
                />
                <Text style={styles.titleWhySignUp}>Congrats!</Text>
                <Text style={styles.titleline2}>
                  Your OTP has been verified
                </Text>
                <Text style={[styles.titleline2, { marginTop: 3 }]}>
                  Successfully.
                </Text>
                <Button
                  buttonStyle={[
                    styles.signUpbtn,
                    {
                      marginTop: Dimensions.get("window").height / 29,
                      height: Dimensions.get("window").height / 15,
                      width: "60%",
                      alignSelf: "center"
                    }
                  ]}
                  containerStyle={{ paddingVertical: 10 }}
                  onPress={this.ModalDonebtn}
                  title="Done"
                  titleStyle={{
                    color: "rgb(35,1,0)",
                    fontSize: 22,
                    fontWeight: "400",
                    alignSelf:'center'
                  }}
                />
              </View>
            </Modal>
          </View>
          {/* Customizable Area End */}
          {/* Merge Engine UI Engine Code */}
        </TouchableWithoutFeedback>
      </ScrollView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  borderStyleBase: {
    width: 30,
    height: 45
  },
  logoStyle: {
    width: 85,
    height: 105,
    alignSelf: "center",
    marginTop: Dimensions.get("window").height / 40
  },
  borderStyleHighLighted: {
    borderColor: "#03DAC6"
  },

  underlineStyleBase: {
    width: 50,
    height: 50,
    borderWidth: 1.5,
    //padding:5,
    borderRadius: 8,
    color: "#rgb(1,1,1)",
    fontSize: 19,
    fontWeight: "bold",
    backgroundColor: "#F7F7F7"
    //borderColor: "#FFCC01",
    //borderColor:"#CCAEAD"
  },

  underlineStyleHighLighted: {
    borderColor: "#FFCC01"
  },

  container: {
    flex: 1,
    padding: 16,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  otp_Text: {
    color: "#rgb(1,1,1)",
    fontSize: 15
  },
  //////
  titleWhySignUp: {
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: "bold",
    color: "#rgb(1,1,1)",
    textAlign: "center",
    marginTop: Dimensions.get("window").height / 51
  },
  titleline2: {
    color: "#9C9C9C",
    fontSize: 13.2,
    fontWeight: "bold",
    textAlign: "center",
    //width: Dimensions.get("window").width / 1,
    marginTop: 10
  },
  signUpbtn: {
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: "#FFCC01",
    //width: width / 1.085,
    height: Dimensions.get("window").height / 15
  },
  bgMobileInput: {
    color: "#9C9C9C",
    flexDirection: "row",
    fontSize: 14.5,
    fontWeight: "400",
    textAlign: "left",
    backgroundColor: "#F7F7F7",
    marginTop: 5,
    borderWidth: 0.8,
    borderColor: "#E6E6E6",
    borderRadius: 7,
    includeFontPadding: true,
    padding: 13
  },
  //////

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  phoneInputMobile: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginBottom: 64,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  phoneInputWeb: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 64,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10
  }
});
// Customizable Area End
