import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

  export interface TabValueArray {
    id: number;
    name: string;
    tab: string;
    description?: string; // Make the 'description' property optional
    reason?: string; // Make the 'reason' property optional
  }
  

const tabValueArray = [
    {
      id: 1,
      name: "abc",
      tab: "pending",
      description:"Hello this is dumy text only for testing  Hello this is dumy description only for testing. Hello this is dumy description only for testing."
    },
    {
      id: 2,
      name: "first",
      tab: "pending",
      description:"Hello this is dumy text only for testing  Hello this is dumy description only for testing. Hello this is dumy description only for testing."
    },
    {
      id: 3,
      name: "xyz",
      tab: "approve",
      description:"Hello this is dumy text only for testing  Hello this is dumy description only for testing. Hello this is dumy description only for testing."
  
    },
    {
      id: 4,
      name: "pqr",
      tab: "rejected",
      reason:"rejected reason paraghraph"
    },
    {
      id: 5,
      name: "asd",
      tab: "rejected",
      reason:"rejected reason paraghraph"
    },
    {
      id: 6,
      name: "gdgdd",
      tab: "pending",
      description:"Hello this is dumy text only for testing  Hello this is dumy description only for testing. Hello this is dumy description only for testing."
    },
    {
      id: 7,
      name: "abc",
      tab: "approve",
      description:"Hello this is dumy text only for testing  Hello this is dumy description only for testing. Hello this is dumy description only for testing."
  
    }, {
      id: 8,
      name: "abc",
      tab: "rejected",
      reason:"rejected reason paraghraph"
    },
  ]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentTab: string;
  tabValueArray:TabValueArray[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultilevelApprovalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentTab: "pending",
      tabValueArray: tabValueArray
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };


  // Customizable Area Start
  async componentDidMount() {
    this.handleTabPress("pending")();
    this.setEnableField()
    this.doButtonPressed()
 }

 handleTabPress = (currentTab: string) => {
   return () => {
     const tabValueArrayData = [...tabValueArray];
     const fiterdTabValueArrayData = tabValueArrayData.filter((item:{ tab: string }) => item.tab === currentTab)
     this.setState({ currentTab: currentTab, tabValueArray: fiterdTabValueArrayData})
   }
 }

 handleRoundButtonPress=()=>{
   this.props.navigation.navigate("MultilevelTemplate");
 }
  // Customizable Area End
}