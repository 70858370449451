import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { BackHandler,Linking,Alert } from 'react-native';
import { getStorageData } from "../../../framework/src/Utilities";
import { Country } from "react-native-country-picker-modal";
//@ts-ignore
import moment from 'moment-timezone';
//@ts-ignore
// import ImageCropPicker from 'react-native-image-crop-picker';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
    name: string,
    email: string,
    full_phone_number: string,
    home_town: string,
    company_name:string,
    blood_group: string,
    educational_qualification: string,
    date_of_birth: string,
    date_of_joining: string,
    country_code: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    type: string,
    employee_id: string,
    employee_type: string,
    seating_location: string,
    location: string,
    department: string,
    designation: string,
    preferred_pronoun: string,
    office_email_id: string,
    brief_about_me: string,
    ask_me_about_expertise: string,
    reporting_to: string,
    employee_status: string,
    landline: string,
    landline_extension: string,
    head_office: string,
    ask_employee_about_expertise: string,
    contact_info: string,
    brief_about_employee: string,
    timings: string,
    time_zone: string,
    state: string,
    company: string,
    image: undefined|string,
    imageuri:null|string,
    enablebtn: string;
    isAboutMe:boolean;
    isBasicInfo:boolean;
    isWork: boolean;
    imageModal:boolean;
    showCamera:boolean;
    employeeIDVal:boolean;
    countryCode: any;
    country: any;
    withCountryNameButton: any;
    withFlag: any;
    withEmoji: any;
    withFilter: any;
    withAlphaFilter: any;
    withCallingCode: any;
    countryName:any;
    stateData:any[];
    timingData:any[];
    bloodData:any[];
    timeZoneData:any[];
    country_name:string;
    address:string;
    textInputnumber:number;
    warningText:string;
    role:string;
    e_learning_data:any;
    validLocation:boolean;
    validPhone:boolean;
    validReport:boolean;
    validWork:boolean;
    validRole:boolean;
    validAskme:boolean;
    validBrief:boolean;
    validHome:boolean;
    validAddress:boolean;
    validEducation:boolean;
    validPronoun:boolean;
    loading:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  e_learning_data:any;
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileCallID:string=''
  updateProfileCallID: string='';
  getStateAPICallId: string='';
  getElearningId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      enablebtn: "1",
      isAboutMe:false,
      isBasicInfo:false,
      isWork: false,
      imageModal:false,
      showCamera:false,
      employeeIDVal:false,
      countryCode: 'IN',
      country: ["91"],
      withCountryNameButton: false,
      withFlag: true,
      withEmoji: true,
      withFilter: true,
      withAlphaFilter: false,
      withCallingCode: true,
      name: "",
      email: "",
      full_phone_number: "",
      home_town: "",
      company_name:"",
      blood_group: "",
      educational_qualification: "",
      date_of_birth: "",
      date_of_joining: "",
      country_code: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      type: "",
      employee_id: "",
      employee_type: "",
      seating_location: "",
      location: "",
      department: "",
      designation: "",
      preferred_pronoun: "",
      office_email_id: "",
      brief_about_me: "",
      ask_me_about_expertise: "",
      reporting_to: "",
      employee_status: "",
      landline: "",
      landline_extension: "",
      head_office: "",
      ask_employee_about_expertise: "",
      contact_info: "",
      brief_about_employee: "",
      timings: 'General',
      time_zone:"",
      state: "",
      company: "",
      image:undefined,
      imageuri:"",
      countryName:"",
      stateData:[],
      timeZoneData:[],
      timingData:[
        {
          value:'General'
        },
        {
          value:'Afternoon-shift'
        },
        {
          value:'Night-shift'
        }
      ],
      bloodData:[{
        value: 'A+ (A Positive)',
        },
        {
        value: 'A- (A Negative)',
        },
        {
        value: 'B+ (B Positive)',
        },
        {
        value: 'B- (B Negative)',
        },
        {
        value: 'O+ (O Positive)',
        },
        {
        value: 'O- (O Negative)',
        },
        {
        value: 'AB+ (AB Positive)',
        },
        {
        value: 'AB- (AB Negative)',
      },
      ],
      country_name:"India",
      address:"",
      textInputnumber:0,
      warningText:"",
      role:"",
      e_learning_data:[],
      validLocation:false,
    validPhone:false,
    validReport:false,
    validWork:false,
    validRole:false,
    validAskme:false,
    validBrief:false,
    validHome:false,
    validAddress:false,
    validEducation:false,
    validPronoun:false,
    loading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonProfile);
    await this.getProfile()
    this.getElearningData()
    // Customizable Area End
  }

  async componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonProfile);
  }
  
  handleBackButtonProfile =()=>{
  this.props.navigation.replace("BottomTabNavigator")
   return true;
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getProfileCallID) {
      this.getProfileDataHandler(responseJson)
    }
    else if (apiRequestCallId === this.updateProfileCallID) {
      this.updateProfileDataHandler(responseJson)
    }
    else if (apiRequestCallId === this.getStateAPICallId) {
      this.getStateDataHandler(responseJson)
    }
    else if (apiRequestCallId === this.getElearningId) {
      this.getElearningDataHandler(responseJson)
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  txtLocationProps= {
    onChangeText: (text: string) => {
      this.setState({location:text},()=>{this.validLocation()})
      //@ts-ignore
      this.txtLocationProps.value = text;
    }
  };
  txtPhoneNumberProps= {
    onChangeText: (text: string) => {
      this.setState({ phone_number: text},()=>{this.validPhone()});
      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };
  txtReportingToProps={
    onChangeText: (text: string) => {
      this.setState({reporting_to:text},()=>{this.validReportingTo()})
      
      //@ts-ignore
      this.txtReportingToProps.value = text;
    }
  };

  txtWorkPhoneProps={
    onChangeText: (text: string) => {
      this.setState({landline:text},()=>{this.validWorkPhone()})
      //@ts-ignore
      this.txtWorkPhoneProps.value = text;
    }
  };

  txtRoleprops={
    onChangeText: (text: string) => {
      this.setState({role:text},()=>{this.validRole()})
      //@ts-ignore
      this.txtRoleprops.value = text;
    }
  };

  txtPreferredNounProps={
    onChangeText: (text: string) => {
      this.setState({preferred_pronoun:text},()=>{this.validPronoun()})
      //@ts-ignore
      this.txtPreferredNounProps.value = text;
    }
  };

  txthomeTownProps = {
    onChangeText: (text: string) => {
      this.setState({ home_town: text },()=>{this.validHometown()});
      //@ts-ignore
      this.txthomeTownProps.value = text;
    }
  };
  txtAddressProps = {
    onChangeText: (text: string) => {
      this.setState({ address: text },()=>{this.validAddress()});
      //@ts-ignore
      this.txtAddressProps.value = text;
    }
  };

  txtEducationProps={
    onChangeText: (text: string) => {
      this.setState({educational_qualification:text},()=>{this.validEducation()})
      //@ts-ignore
      this.txtEducationProps.value = text;
    }
  };

  txtBriefAboutMeProps={
    onChangeText: (text: string) => {
      this.setState({brief_about_me:text},()=>{this.validBriefText()})
      //@ts-ignore
      this.txtBriefAboutMeProps.value = text;
    }
  };

  txtAskMeProps={
    onChangeText: (text: string) => {
      this.setState({ask_me_about_expertise:text},()=>{this.validAskmeText()})
      //@ts-ignore
      this.txtAskMeProps.value = text;
    }
  };

  openUrl(video:any) {
    if(video){
      Linking?.openURL(video)
    }
    else{
      Alert?.alert("Video is not available")
    }
  }


  formatViewcount(labelValue:any) {
    const numericValue = Math.abs(Number(labelValue));

    if (numericValue >= 1.0e+9) {
        return (numericValue / 1.0e+9).toFixed(2).replace(/\.00$/, '') + "B";
    } else if (numericValue >= 1.0e+6) {
        return (numericValue / 1.0e+6).toFixed(2).replace(/\.00$/, '') + "M";
    } else if (numericValue >= 1.0e+3) {
        return (numericValue / 1.0e+3).toFixed(2).replace(/\.00$/, '') + "K";
    } else {
        return numericValue.toString();
    }
}
  

  getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return maxDate;
  };
  validLocation=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.location.includes(char))
        if (this.state.location == "") {
          this.setState({textInputnumber:1, warningText:"Enter valid Location"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:1, warningText:"Remove special characters in location"})
          return false
        }
        else if(numbers.test(this.state.location)){
          this.setState({ textInputnumber:1, warningText:"Remove numbers in location"})
          return false
        }
        else if(emojis.test(this.state.location)){
          this.setState({textInputnumber:1, warningText:"Remove emojis in location"})
          return false
        }
        else if(this.state.location.trim() !== this.state.location || /\s{2,}/.test(this.state.location)){
          this.setState({textInputnumber:1, warningText:"Remove blank spaces in location"})
          return false
        }
        else if(this.state.location.length < 3){
          this.setState({textInputnumber:1, warningText:"Enter atleast three characters in location"})
          return false
        }
        else{
          this.setState({warningText:"",textInputnumber:0, validLocation:true})
          return true
        }
  }

  validPhone=async()=>{
    let re = /^\d+$/;
    if (this.state.phone_number ==''){
      this.setState({textInputnumber:2, warningText:"Enter phone number"})
      return false
    }
    else if (this.state.phone_number.startsWith('0')){
      this.setState({textInputnumber:2, warningText:"Enter valid phone Number"})
      return false
    }
     else if (!re.test(this.state.phone_number)) {
      this.setState({textInputnumber:2, warningText:"Enter valid phone number"})
      return false
    } 
    else if(this.state.phone_number.trim() !== this.state.phone_number || /\s{2,}/.test(this.state.phone_number)){
      this.setState({textInputnumber:2, warningText:"Remove blank spaces in phone number"})
      return false
    }
    else{
      this.setState({textInputnumber:0,warningText:"", validPhone:true})
      return true
    }
  }

  validPronoun=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.preferred_pronoun.includes(char))
        if (this.state.preferred_pronoun == "") {
          this.setState({textInputnumber:3, warningText:"Enter valid pronounce"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:3, warningText:"Remove special characters in pronounce"})
          return false
        }
        else if(numbers.test(this.state.preferred_pronoun)){
          this.setState({ textInputnumber:3, warningText:"Remove numbers in pronounce"})
          return false
        }
        else if(emojis.test(this.state.preferred_pronoun)){
          this.setState({textInputnumber:3, warningText:"Remove emojis in pronounce"})
          return false
        }
        else if(this.state.preferred_pronoun.trim() !== this.state.preferred_pronoun || /\s{2,}/.test(this.state.preferred_pronoun)){
          this.setState({textInputnumber:3, warningText:"Remove blank spaces in pronounce"})
          return false
        }
        else if(this.state.preferred_pronoun.length < 3){
          this.setState({textInputnumber:3, warningText:"Enter atleast three characters in pronounce"})
          return false
        }
        else{
          this.setState({warningText:"",textInputnumber:0, validPronoun:true})
          return true
        }
  }

  validAddress=async()=>{
    const addressFormat= /^[^\p{Z}\p{S}\p{Emoji_Presentation}]*$/u;
    const emojis=  /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FAB0}-\u{1FABF}\u{1F004}-\u{1F0CF}\u{1F170}-\u{1F251}]/u
        if (this.state.address == "") {
          this.setState({textInputnumber:4, warningText:"Enter valid address"})
          return false
        }
        else if(this.state.address.length < 3){
          this.setState({textInputnumber:4, warningText:"Enter atleast three characters in address"})
          return false
        }
        else if(addressFormat.test(this.state.address) ){
          this.setState({ textInputnumber:4, warningText:"Enter valid Address"})
          return false
        }
        else if(emojis.test(this.state.address)){
          this.setState({textInputnumber:4, warningText:"Remove emojis in address"})
          return false
        }
        else if(this.state.address.trim() !== this.state.address || /\s{2,}/.test(this.state.address)){
          this.setState({textInputnumber:4, warningText:"Remove blank spaces in address"})
          return false
        }
        else{
          this.setState({warningText:"",textInputnumber:0, validAddress:true})
          return true
        }
  }
  
  validHometown=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.home_town.includes(char))
        if (this.state.home_town == "") {
          this.setState({textInputnumber:5, warningText:"Enter valid home town"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:5, warningText:"Remove special characters in home town"})
          return false
        }
        else if(numbers.test(this.state.home_town)){
          this.setState({ textInputnumber:5, warningText:"Remove numbers in home town"})
          return false
        }
        else if(emojis.test(this.state.home_town)){
          this.setState({textInputnumber:5, warningText:"Remove emojis in home town"})
          return false
        }
        else if(this.state.home_town.trim() !== this.state.home_town || /\s{2,}/.test(this.state.home_town)){
          this.setState({textInputnumber:5, warningText:"Remove blank spaces in home town"})
          return false
        }
        else if(this.state.home_town.length < 3){
          this.setState({textInputnumber:5, warningText:"Enter atleast three characters in home town"})
          return false
        }
        else{
          this.setState({warningText:"",textInputnumber:0, validHome:true})
          return true
        }
  }

  validEducation=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.educational_qualification.includes(char))
        if (this.state.educational_qualification == "") {
          this.setState({textInputnumber:6, warningText:"Enter valid highest education"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:6, warningText:"Remove special characters in highest education"})
          return false
        }
        else if(numbers.test(this.state.educational_qualification)){
          this.setState({ textInputnumber:6, warningText:"Remove numbers in highest education"})
          return false
        }
        else if(emojis.test(this.state.educational_qualification)){
          this.setState({textInputnumber:6, warningText:"Remove emojis in highest education"})
          return false
        }
        else if(this.state.educational_qualification.trim() !== this.state.educational_qualification || /\s{2,}/.test(this.state.educational_qualification)){
          this.setState({textInputnumber:6, warningText:"Remove blank spaces in highest education"})
          return false
        }
        else if(this.state.educational_qualification.length < 2){
          this.setState({textInputnumber:6, warningText:"Enter valid education"})
          return false
        }
        else{
          this.setState({warningText:"",textInputnumber:0, validEducation:true})
          return true
        }
  }

  validBriefText=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.brief_about_me.includes(char))
        if (this.state.brief_about_me == "") {
          this.setState({textInputnumber:7, warningText:"Enter valid brief about me"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:7, warningText:"Remove special characters in brief about me"})
          return false
        }
        else if(numbers.test(this.state.brief_about_me)){
          this.setState({ textInputnumber:7, warningText:"Remove numbers in brief about me"})
          return false
        }
        else if(emojis.test(this.state.brief_about_me)){
          this.setState({textInputnumber:7, warningText:"Remove emojis in brief about me"})
          return false
        }
        else if(this.state.brief_about_me.trim() !== this.state.brief_about_me || /\s{2,}/.test(this.state.brief_about_me)){
          this.setState({textInputnumber:7, warningText:"Remove blank spaces in brief about me"})
          return false
        }
        else if(this.state.brief_about_me.length < 3 ){
          this.setState({textInputnumber:7, warningText:"Enter atleast three characters in brief about me"})
          return false
        }
        else{
          this.setState({warningText:"",textInputnumber:0, validBrief:true})
          return true
        }
  }

  validAskmeText=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.ask_me_about_expertise.includes(char))
        if (this.state.ask_me_about_expertise == "") {
          this.setState({textInputnumber:8, warningText:"Enter valid brief about me"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:8, warningText:"Remove special characters in brief about me"})
          return false
        }
        else if(numbers.test(this.state.ask_me_about_expertise)){
          this.setState({ textInputnumber:8, warningText:"Remove numbers in brief about me"})
          return false
        }
        else if(emojis.test(this.state.ask_me_about_expertise)){
          this.setState({textInputnumber:8, warningText:"Remove emojis in brief about me"})
          return false
        }
        else if(this.state.ask_me_about_expertise.trim() !== this.state.ask_me_about_expertise || /\s{2,}/.test(this.state.ask_me_about_expertise)){
          this.setState({textInputnumber:8, warningText:"Remove blank spaces in brief about me"})
          return false
        }
        else if(this.state.ask_me_about_expertise.length < 3 ){
          this.setState({textInputnumber:8, warningText:"Enter atleast three characters in brief about me"})
          return false
        }
        else{
          this.setState({warningText:"",textInputnumber:0, validAskme:true})
          return true
        }
  }

  validReportingTo=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.reporting_to.includes(char))
        if (this.state.reporting_to == "") {
          this.setState({textInputnumber:9, warningText:"Enter valid reporting person"})
          return false
        }
        else if(this.state.reporting_to.length < 3){
          this.setState({textInputnumber:9, warningText:"Enter atleast three characters in reporting person"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:9, warningText:"Remove special characters in reporting person"})
          return false
        }
        else if(numbers.test(this.state.reporting_to)){
          this.setState({ textInputnumber:9, warningText:"Remove numbers in reporting person"})
          return false
        }
        else if(emojis.test(this.state.reporting_to)){
          this.setState({textInputnumber:9, warningText:"Remove emojis in reporting person"})
          return false
        }
        else if(this.state.reporting_to.trim() !== this.state.reporting_to || /\s{2,}/.test(this.state.reporting_to)){
          this.setState({textInputnumber:9, warningText:"Remove blank spaces in reporting person"})
          return false
        } 
        else{
          this.setState({warningText:"",textInputnumber:0, validReport:true})
          return true
        }
  }

  validWorkPhone=async()=>{
    let re = /^\d+$/;
    if (this.state.landline ==''){
      this.setState({textInputnumber:10, warningText:"Enter work phone number"})
      return false
    }
    else if (this.state.landline.startsWith('0')){
      this.setState({textInputnumber:10, warningText:"Enter valid work phone Number"})
      return false
    }
     else if (!re.test(this.state.landline)) {
      this.setState({textInputnumber:10, warningText:"Enter valid work phone number"})
      return false
    } 
    else if(this.state.landline.trim() !== this.state.landline || /\s{2,}/.test(this.state.landline)){
      this.setState({textInputnumber:10, warningText:"Remove blank spaces in work phone number"})
      return false
    }
    else{
      this.setState({textInputnumber:0,warningText:"", validWork:true})
      return true
    }
  }

  validRole=async()=>{
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const numbers= /\d/
    const emojis= /\p{Emoji}/u
    const isSpecialCharsPresent = specialChars.split('').some(char => this.state.role.includes(char))
        if (this.state.role == "") {
          this.setState({textInputnumber:11, warningText:"Enter valid role"})
          return false
        }
        else if(this.state.role.length < 3){
          this.setState({textInputnumber:11, warningText:"Enter atleast three characters in role"})
          return false
        }
        else if(isSpecialCharsPresent ){
          this.setState({ textInputnumber:11, warningText:"Remove special characters in role"})
          return false
        }
        else if(numbers.test(this.state.role)){
          this.setState({ textInputnumber:11, warningText:"Remove numbers in role"})
          return false
        }
        else if(emojis.test(this.state.role)){
          this.setState({textInputnumber:11, warningText:"Remove emojis in role"})
          return false
        }
        else if(this.state.role.trim() !== this.state.role || /\s{2,}/.test(this.state.role)){
          this.setState({textInputnumber:11, warningText:"Remove blank spaces in role"})
          return false
        } 
        else{
          this.setState({warningText:"",textInputnumber:0, validRole:true})
          return true
        }
  }

  validationAboutMe=async()=>{
    let location = await this.validLocation()
    let phone = await this.validPhone()
    if (location && phone)
      this.isAboutmeBody()
    else if (!location)
      this.validLocation()
    else if (!phone)
      this.validPhone()
    else
      console.log("Validations passed")
  }

  isAboutmeBody=()=>{
    let fullNumber = "+" + this.state.country[0] + this.state.phone_number
    let formdata1 = new FormData();
    this.state.location !== "" && formdata1.append("location", this.state.location);
    this.state.phone_number !== "" && formdata1.append("full_phone_number",fullNumber)
    this.state.country_name !== "" && formdata1.append("country_name", this.state.country_name)
    this.state.state !== "" && formdata1.append("state", this.state.state);
    this.state.timings !=="" && formdata1.append("timings", this.state.timings);
    this.state.time_zone !== "" && formdata1.append("time_zone", this.state.time_zone);
    this.updateProfile(formdata1)
  }

  validationBasicInfo=async()=>{
    let pronoun = this.state.preferred_pronoun !== "" && await this.validPronoun()
    let address= this.state.address !== "" && await this.validAddress()
    let home = this.state.home_town !=="" && await this.validHometown()
    let education=this.state.educational_qualification !== "" && await this.validEducation()
    let brief= this.state.brief_about_me !== "" && await this.validBriefText()
    let askme= this.state.ask_me_about_expertise !== "" && await this.validAskmeText()
    if (pronoun && address && home && education && brief && askme)
      this.isBasicInfoBody()
    else if (!pronoun)
      this.validPronoun()
    else if (!address)
      this.validAddress()
    else if (!home)
      this.validHometown()
    else if (!education)
      this.validEducation()
    else if (!brief)
      this.validBriefText()
    else if (!askme)
      this.validAskmeText()
    else
      console.log("Validations passed")
  }

  isBasicInfoBody=()=>{
    let formdata3 = new FormData();
    this.state.preferred_pronoun !== "" && formdata3.append("preferred_pronounce", this.state.preferred_pronoun);
    this.state.address !== ""  && formdata3.append("address", this.state.address);
    this.state.home_town !=="" && formdata3.append("home_town", this.state.home_town);
    this.state.blood_group !== null && formdata3.append("blood_group", this.state.blood_group);
    this.state.date_of_birth !== null &&formdata3.append("date_of_birth", this.state.date_of_birth);
    this.state.educational_qualification !== "" && formdata3.append("higher_education", this.state.educational_qualification);
    this.state.brief_about_me !== "" && formdata3.append("brief_about_me", this.state.brief_about_me);
    this.state.ask_me_about_expertise !== "" && formdata3.append("ask_me_about_expertise", this.state.ask_me_about_expertise);
    this.updateProfile(formdata3)
  }
  validationIsWork=async()=>{
    let report = this.state.reporting_to !== "" && await this.validReportingTo()
    let landline = this.state.landline !== "" && await this.validWorkPhone()
    let role= this.state.role !== "" && await this.validRole()
    if (report && landline && role)
      this.isWorkBody()
    else if (!report)
      this.validReportingTo()
    else if (!landline)
      this.validWorkPhone()
    else if (!role)
      this.validRole()
    else
      console.log("Validations passed")
    
  }

  isWorkBody=()=>{
    let formdata2 = new FormData();
    this.state.reporting_to !== "" && formdata2.append("reporting_to", this.state.reporting_to);
    this.state.date_of_joining !== null && formdata2.append("date_of_joining", this.state.date_of_joining);
    this.state.landline !== "" && formdata2.append("landline", this.state.landline);
    this.state.role !== "" && formdata2.append("role", this.state.role);
    this.updateProfile(formdata2)
  }

  imageBody= ()=>{
    
    if(this.state.imageuri){
      let formdata = new FormData();
      
    const imageURI = {
      uri: this.state.imageuri,
      type: 'image/jpg', // Change the type accordingly (e.g., image/png, image/jpeg, etc.)
      name: 'image.jpg', // Change the name accordingly
    };
    //@ts-ignore
    formdata.append("image", imageURI)
    this.updateProfile(formdata)
    }
  }

  onSelect = (country: Country) => {
    //@ts-ignore
    this.setState({ countryCode: country.cca2, country_name:country.name})
    this.setState({ country: country.callingCode })
    this.getTimeZone(country.cca2)
    this.getStateAPICall(country.name)
  }
  
  getTimeZone = (code:any) => {
    const timezonesForCountry = moment.tz.zonesForCountry(code);
    const utcTimezones = timezonesForCountry.map(timezone => {
      const offset = moment.tz(timezone).utcOffset();
      const offsetHours = Math.abs(Math.floor(offset / 60));
      const offsetMinutes = Math.abs(offset % 60);
      const utcOffset = `UTC${offset >= 0 ? '+' : '-'}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
      return utcOffset;
    });
    const timezoneArray = utcTimezones.map(state => ({ value: state }))
    this.setState({timeZoneData:timezoneArray})
  }

  openCamera = async () => {
    // let path = null;
    // await ImageCropPicker.openCamera({
    //   cropping: true, mediaType:"photo"
    // })
    //   .then(res => {
    //     path = res.path;
    //   })
    //   .catch(error => {
    //     this.CameraPermissionAlert(error);
    //     console.log('Error=', error);
    //   });
    // return path;
  };
  
  OpenGallery = async () => {
    // let path = null;
    // await ImageCropPicker.openPicker({cropping: true, mediaType:"photo"})
    //   .then(res => {
    //     path = res.path;
    //   })
    //   .catch(error => {
    //     this.CameraPermissionAlert(error);
    //     console.log('Error=', error);
    //   });
    // return path;
  };
  
  CameraPermissionAlert = (error:any) => {
    if (error?.code === 'E_NO_CAMERA_PERMISSION') {
      Alert.alert(
        'Permission Required',
        'This app requires camera permission to take pictures. Please go to Settings and enable the Camera permission.',
        [
          {
            text: 'Cancel',
          },
          {
            text: 'Ok',
            onPress: () => {
              Linking.openSettings();
            },
          },
        ],
      );
    }
  };
  getElearningData = ()=>{
    const header = {
      "Content-Type": "application/json",
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getElearningId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.geteElearningAPiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getElearningDataHandler = (responseJson:any) =>{
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({
          e_learning_data: responseJson.data,
        });
      }
    }
  }
  getProfile=async()=>{
    this.setState({loading:true})
    const id=await getStorageData('user_id')
    const header = {
      "Content-Type": "application/json",
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getProfileCallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileAPiEndPoint+'?id='+id
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  profileDataSet=(response:any)=>{
    this.setState({
      name: response.name,
      email: response.email,
      full_phone_number: response.full_phone_number,
      home_town: response.home_town,
      company_name:response.company_name,
      blood_group: response.blood_group,
      educational_qualification: response.higher_education,
      date_of_birth: response.date_of_birth,
      date_of_joining: response.date_of_joining,
      country_code: response.country_code,
      country_name:response.country_name,
      first_name: response.first_name,
      last_name: response.last_name,
      phone_number: response.phone_number,
      type: response.type,
      employee_id: response.employee_id,
      employee_type: response.employee_type,
      seating_location: response.seating_location,
      location: response.location,
      department: response?.department !== null ? response?.department?.name : "",
      designation: response.designation,
      preferred_pronoun: response.preferred_pronounce,
      office_email_id: response.office_email_id,
      brief_about_me: response.brief_about_me,
      ask_me_about_expertise: response.ask_me_about_expertise,
      reporting_to: response.reporting_to,
      employee_status: response.employee_status,
      landline: response.landline,
      landline_extension: response.landline_extension,
      head_office: response.head_office,
      ask_employee_about_expertise: response.ask_me_about_expertise,
      contact_info: response.contact_info,
      brief_about_employee: response.brief_about_employee,
      timings: response.timings,
      time_zone: response.time_zone,
      state: response.state,
      image:response.image,
      role: response.role,
      address: response.address
    })
    this.getTimeZone("IN")
    this.getStateAPICall(response.country_name)
  }
  getProfileDataHandler = (responseJson:any) =>{
    if (!responseJson)
      Alert.alert('Something went wrong, please try again later.!!')
    else if (responseJson) {
      const response = responseJson.data.attributes
      this.profileDataSet(response)
      this.setState({ loading: false })
    }
  }
  updateProfile=async(bodyData:any)=>{
    this.setState({loading:true})
    const id=await getStorageData('user_id')
    const token=await getStorageData('authToken')
    const header = {
      "token": token,
      "Content-Type": "multipart/form-data"
    }
  
    let body = bodyData
    
    console.log('-----------------------');
      console.log(body);
    console.log('-----------------------');
    const apiUrl= 'account_block/accounts/'+id
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateProfileCallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiType
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  updateProfileDataHandler = (responseJson: any) => {
    if (!responseJson)
      Alert.alert('Something went wrong, please try again later.!!')
    else if (responseJson) {
      const response = responseJson.data.attributes
      this.profileDataSet(response)
      this.setState({ isAboutMe: false, isBasicInfo: false, isWork: false, loading: false })
    }
  }
  getStateAPICall = async (country:any) => {
    const enpointUrl: any = 'account_block/show_country?country_name='+country;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateAPICallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      enpointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStateDataHandler = (responseJson: any) => {
    if (!responseJson)
      Alert.alert('Something went wrong, please try again later.!!')
    else if (responseJson) {
      const response = responseJson.state
      const outputArray = [];
      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          const value = response[key].name;
          outputArray.push({ value });
        }
      }
      this.setState({ stateData: outputArray })
    }
  }


  // Customizable Area End
}
